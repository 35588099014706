import { useEffect, useState } from "react";
import "./index.scss";
import { useTranslation } from "react-i18next";

const ComingSoon = ({
  title,
  targetDate,
}: {
  title: string;
  targetDate: number;
}) => {
  const [timer, setTimer] = useState<string>();
  const { t } = useTranslation();

  // Her saniyede bir güncellenen fonksiyon
  const updateCounter = () => {
    const currentDate = new Date().getTime();
    const timeDifference = targetDate - currentDate;

    // Zamanı hesapla
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    );
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    // Sayacı güncelle
    const formattedDays = days.toString().padStart(2, "0");
    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = seconds.toString().padStart(2, "0");

    // Sayacı güncelle
    setTimer(
      `${formattedDays}:${formattedHours}:${formattedMinutes}:${formattedSeconds}`
    );
  };

  useEffect(() => {
    setInterval(updateCounter, 1000);
    updateCounter();
  }, []);

  return (
    <div className="comingSoon">
      <img className="mobile-hidden" src={"/assets/imgs/time.png"} alt="time" />
      <img
        className="mobile-visibile"
        src={"/assets/imgs/time3.png"}
        alt="time"
      />
      <div className="content">
        <h2>
          <b>"{title}"</b> {t("soonText")}
        </h2>
        <div className="timer">{timer}</div>
      </div>
      <img
        className="mobile-hidden"
        src={"/assets/imgs/time2.png"}
        alt="time"
      />
    </div>
  );
};

export default ComingSoon;

// const currentDate = new Date().getTime();
// const timeDifference = targetDate - currentDate;

// // Zamanı hesapla
// const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
// const hours = Math.floor(
//   (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
// );
// const minutes = Math.floor(
//   (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
// );
// const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

// const day = days > 0 ? `${days} gün ` : "";
// const hour = hours > 0 ? `${hours} saat ` : "";
// const minute = minutes > 0 ? `${minutes} dakika ` : "";

// // Sayacı güncelle
// setTimer(`${day}${hour}${minute}${seconds} saniye`);
