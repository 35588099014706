import ComingSoon from "components/comingSoon";
import "./index.scss";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

const BlogPage = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("blogPageTitle");
  }, []);

  return (
    <div className="blog">
      <ComingSoon
        title="BLOG"
        targetDate={new Date("December 31, 2024 23:59:59").getTime()}
      ></ComingSoon>
    </div>
  );
};

export default BlogPage;
