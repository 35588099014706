import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const language = localStorage.getItem("language") ?? "tr";

const resources = {
  en: {
    translation: {
      homeBannerText: "WE ARE DELTA SOFTWARE",
      homeBannerTitle: "DISCOVER THE NEW FACE OF THE SOFTWARE WORLD",
      servicesTitle: "OUR SERVICES",
      servicesText:
        "At Delta Software, we meticulously handle your projects and work to achieve the highest quality results.",
      servicesItemTitle1: "WEB APPLICATION",
      servicesItemText1:
        "With our expertise in web applications, we accelerate the digital transformation of your business.",
      servicesItemTitle2: "MOBILE APPLICATION",
      servicesItemText2:
        "We develop hybrid and native mobile applications to professionally establish your presence in the mobile world.",
      servicesItemTitle3: "RPA SOFTWARE",
      servicesItemText3:
        "Through RPA software, we transfer time-consuming, routine tasks or operations to digital robots.",
      servicesItemTitle4: "CYBER SECURITY",
      servicesItemText4:
        "Our cyber security team protects your computers, mobile devices, and data from malicious attacks.",
      servicesItemTitle5: "GRAPHIC AND VIDEO DESIGN",
      servicesItemText5:
        "With our design team, we create original digital content that strengthens your brand perception.",
      servicesItemTitle6: "DIGITAL MARKETING",
      servicesItemText6:
        "We offer quality digital marketing solutions to help you reach wider markets with your products.",
      ourTechnologiesTitle: "OUR TECHNOLOGIES",
      ourTechnologiesText:
        "Let's develop your projects with the right tools and up-to-date technologies at the right time, tailored to your needs.",
      projectDesingTitle: "YOUR PROJECT'S JOURNEY",
      projectDesingText:
        "In today's rapidly digitizing world, let's professionalize your project processes together.",
      faqTitle: "FREQUENTLY ASKED QUESTIONS",
      faqText: "Find answers to questions about our company and services.",
      faqItemTitle1: "What is Delta Software?",
      faqItemAnswer1:
        "Delta Software is the software brand of Özden Group Inc., operating in three different countries for over 10 years. It provides digital services focused on web design, marketing, social media management, web software, and mobile application development. Collaborating with brands, Delta Software facilitates a seamless transition to the new digital world.",
      faqItemTitle2: "What does Delta Software do?",
      faqItemAnswer2:
        "Delta Software empowers all brands looking to take their brand digital and establish a corporate identity with the power of current technologies. It ensures the fulfillment of marketing, application, program, and infrastructure requirements.",
      faqItemTitle3: "Is Delta Software secure?",
      faqItemAnswer3:
        "Delta Software, as an Istanbul-based software company, operates securely at Mega Center Business Center, Block C26, Floor 5, Independent Sections 36-37-38-39-40-41. With over 10 years of experience in the industry, it provides uninterrupted service to its customers.",
      faqItemTitle4: "Which Companies Must Establish a Website?",
      faqItemAnswer4:
        "According to changes in the Turkish Commercial Code, as of February 14, 2011, with the official gazette numbered 27846 and law numbered 6102, it became mandatory for certain qualified joint-stock companies to be audited by independent institutions and organizations. It is considered mandatory for these audited companies to establish a website.",
      faqItemTitle5: "Does Delta Software create Mobile Responsive Websites?",
      faqItemAnswer5:
        "Delta Software regularly keeps track of the possibilities of technologies and plans for the future in the projects it develops. In this context, it ensures mobile responsiveness (Responsive) in the web projects it carries out.",
      faqItemTitle6: "What is Custom Software Development by Delta Software?",
      faqItemAnswer6:
        "Delta Software enhances the efficiency of brands' business processes by professionalizing processes with custom software that easily integrates. Custom software is designed directly according to the needs and goals of the respective brands.",
      faqItemTitle7: "What are the Prices for Custom Software Development?",
      faqItemAnswer7:
        "Each company's workflow and business process are unique. Therefore, the fees for custom software services vary. You can contact Delta Software to get details about custom software developed specifically for your brand.",
      faqItemTitle8: "Does Delta Software Provide Post-Project Support?",
      faqItemAnswer8:
        "Delta Software provides its services continuously. It provides maintenance and technical support for the projects developed in collaboration with business partners.",
      faqItemTitle9: "Do Delta Software Projects Include E-Commerce?",
      faqItemAnswer9:
        "Delta Software provides all software services, including E-Commerce, Corporate Websites, Mobile Applications, or software developed for specific purposes.",
      faqItemTitle10: "What Does Delta Software Offer in Digital Marketing?",
      faqItemAnswer10:
        "With special projects in advertising and marketing, Delta Software creates your brand's corporate identity, increases your visibility in the digital world, boosts your sales volume, and ensures your prominence in the industry.",
      projectFormTitle: "READY TO BRING YOUR PROJECT TO LIFE",
      projectFormText:
        "We listen to you, understand your needs, and develop projects that best suit your brand.",
      projectFormInputName: "Full Name*",
      projectFormInputEmail: "Email Address",
      projectFormInputTel: "Phone Number*",
      projectFormInputFile: "Upload Project File if Any*",
      projectFormServices: "Services",
      projectFormCheckboxWeb: "Web Application",
      projectFormCheckboxMobile: "Mobile Application",
      projectFormCheckboxRPA: "RPA Software",
      projectFormCheckboxCyber: "Cyber Security",
      projectFormCheckboxGraphic: "Graphic and Video Design",
      projectFormCheckboxDigital: "Digital Marketing",
      projectFormDetail: "Details",
      projectFormTextArea: "Provide Detailed Information About Your Request.",
      projectFormAgreement:
        "Your personal data is processed within the scope of the Information Text. By filling out the form, you confirm that you have read and accepted the Information Text, Privacy Policy, and Cookie Policy.",
      projectFormButton: "SUBMIT APPLICATION",
      headerStartProjectButton: "Start Your Project",
      headerMenuAbout: "ABOUT US",
      headerMenuServices: "SERVICES",
      headerMenuBlog: "BLOG",
      headerMenuCareer: "CAREER",
      headerMenuContact: "CONTACT",
      headerMenuProjectStart: "START PROJECT",
      headerMenuSocialMedia: "SOCIAL MEDIA",
      headerMenuSocialLanguage: "LANGUAGE",
      headerMenuSocialLanguageEnglish: "ENGLISH",
      headerMenuSocialLanguageTurkish: "TURKISH",
      footerContact: "CONTACT",
      footerInstitutional: "INSTITUTIONAL",
      footerServices: "SERVICES",
      footerContactNow: "STAY IN CONTACT",
      footerMenuInstitutional1: "About Us",
      footerMenuInstitutional2: "Open Positions",
      footerMenuInstitutional3: "Privacy Policy",
      footerMenuInstitutional4: "Cookie Policy",
      footerMenuInstitutional5: "Personal Data Application Form",
      footerMenuServices1: "Web Application",
      footerMenuServices2: "Mobile Application",
      footerMenuServices3: "RPA Software",
      footerMenuServices4: "Cyber Security",
      footerMenuServices5: "Graphic and Video Design",
      footerMenuServices6: "Digital Marketing",
      footerMenuContactNowText: "Sign up for the latest news and promotions",
      footerMenuContactNowInput: "Your Email Address",
      aboutTitle: "Who is DELTA SOFTWARE?",
      aboutText:
        "We are a software company founded in 2017 with the goal of becoming Turkey's software hub. With our growing team and partnerships established over the years, we are progressing towards achieving our goal. We provide comprehensive Web/Mobile Application development services to customers who come to us with their ideas, ranging from project management to UI/UX design; we offer consultancy services with our experienced team in DevOps, Dynatrace, and AWS.",
      aboutWhoAreWe: "Who Are We",
      aboutWhoAreWeTitle: "Our Team, Our Projects, and Our Experience",
      aboutWhoAreWeText:
        "We are a software company founded in 2017 with the goal of becoming Turkey's software hub. With our growing team and partnerships established over the years, we are progressing towards achieving our goal. We provide comprehensive Web/Mobile Application development services to customers who come to us with their ideas, ranging from project management to UI/UX design; we offer consultancy services with our experienced team in DevOps, Dynatrace, and AWS.",
      aboutWhoAreWeBox1: "TEAM MEMBER",
      aboutWhoAreWeBox2: "COMPLETED PROJECTS",
      aboutWhoAreWeBox3: "SERVICE IN DIFFERENT COUNTRIES",
      aboutWhoAreWeBox4: "INDUSTRY EXPERIENCE",
      aboutWhoAreWeYear: "YEAR",
      aboutOurVision: "Our Vision & Mission",
      aboutOurVisionTitle:
        "We Explore, Develop, Step Into the Technology of the Future!",
      aboutOurVisionText:
        "As Delta Software, we bring to life the digital transformations of the brands we collaborate with, preparing them for the digital future. With our technological infrastructure and experienced team closely following evolving technologies, we determine and plan your digital transformation strategy. We guarantee your satisfaction by producing results-oriented work with innovative solutions and creative ideas that highlight your brand identity. In this process, we are ready for a professional partnership that will bring together all the digital processes of your brand under one roof.",
      soonText: "COMING SOON!",

      CookieTitle: "COOKIE POLICY",
      CookieSubTitle1: "Your Personal Data Processed Through Cookies",
      CookieSubTitle2: "Storage and Access of Cookies",
      CookieSubTitle3: "Controlling Cookies",
      CookieText1:
        "In compliance with the information obligation arising from the KVKK Law, we would like to inform you about your personal data processed through this Cookie Policy on http://deltasoftware.tech/. Almost every website today uses cookies. Cookies are small data files placed on your computer through your browser by the web server. When a connection is established between your browser and the server, the site recognizes you through cookies. Therefore, the cookies we use when you visit our Site allow information about your site visit to be stored on your device and used during your subsequent visits, providing you with convenience.",
      CookieText2:
        "Through cookies, your usage and visit habits on the Internet Site, session information, IP address, data, and past transactions are processed and used.",
      CookieText3:
        "The content offered on our Site is also provided through domain names other than the domain name in the access address. In such cases, each domain name creates third-party cookies unique to itself.",
      CookieText4:
        "With the cookies we use on our Site, we aim to provide you with a more efficient and functional internet experience. A Data Privacy Agreement has been concluded between our Company and third-party service providers in accordance with the KVKK legislation, and the security of your personal data obtained on behalf of our Company by these individuals has been guaranteed.",
      CookieText5:
        "The cookies created by our Site are stored on your computer by the internet site browser you use to access our Site, except for the domain name http://deltasoftware.tech/. The information contained in these cookies is accessible remotely by our Site provided that the same browser is used.",
      CookieText6:
        "Generally, internet browsers are preset to automatically accept cookies. Browsers can be set to block cookies or provide a warning to the user when a cookie is sent to the device. Managing cookies varies from browser to browser, so for detailed information, you can check the browser's help menu.",
      CookieText7:
        "On the other hand, you can delete cookies stored on your device or view and track the list and values of these cookies through your browser.",

      kvkkMainTitle: "GENERAL EXPLANATIONS",
      kvkkSecondTitle1:
        "1. Scope of the Personal Data Protection and Processing Policy",
      kvkkSecondTitle2: "2. Reasons for Processing Your Data",
      kvkkSecondTitle3: "3. What Type of Data We Collect from You?",
      kvkkSecondTitle4:
        "4. For What Purpose Your Information is Collected on Delta Software Websites and Digital Platforms?",
      kvkkSecondTitle5: "5. How and When Do We Collect Your Information?",
      kvkkSecondTitle6: "6. How Do We Collect Your Personal Data?",
      kvkkSecondTitle7: "Cookie Policy",
      kvkkSecondTitle8:
        "7. To Whom and for What Purpose Can Processed Personal Data Be Transferred?",
      kvkkSecondTitle9: "8. Method and Legal Basis of Personal Data Collection",
      kvkkSecondTitle10:
        "9. Rights of the Personal Data Subject as Listed in Article 11 of the KVKK Law",
      kvkkSecondTitle11:
        "10. Links to Third-Party Websites or Mobile Applications Through Delta Software Websites and Digital Platforms",
      kvkkSecondTitle12: "11. How Do We Protect Your Information?",
      kvkkSecondTitle13: "12. How Do I Manage My Information?",
      kvkkSecondTitle14: "13. How Do I Block Electronic Communications?",
      kvkkSecondTitle15: "14. Children",
      kvkkSecondTitle16: "15. Updating the Policy",
      kvkkSubTitle1: "Information",
      kvkkSubTitle2:
        "2.3. Conditions for Processing Special Categories of Personal Data",
      kvkkSubTitle3: "6.1 Membership/Registration",
      kvkkSubTitle4: "6.2 Credit Card Information",
      kvkkSubTitle5:
        "6.3 Content Generated or Publicly Available by You (Comments, Ratings, etc.)",
      kvkkSubTitle6:
        "6.4. Personal Information Collected in the Context of Contests, Draws, Surveys",
      kvkkSubTitle7:
        "6.5. Information We Collect Automatically and Without Your Personal Data",
      kvkkSubTitle8: "6.6. Device Information",
      kvkkSubTitle9: "6.7. Data Collected Through Cookies and Beacons",

      kvkkText1:
        "Delta Software values the privacy of its visitors, members, and users in the scope of the services provided through its website, mobile applications, and other digital platforms. It respects the concerns of visitors, members, and users regarding the privacy and security of the shared personal information. In this context, this text has been prepared to inform and enlighten you in accordance with the Law on the Protection of Personal Data No. 6698 ('Law', 'KVKK') and other relevant legislation.",
      kvkkText2:
        "The Law on the Protection of Personal Data No. 6698 was adopted on March 24, 2016, and entered into force by being published in the Official Gazette on April 7, 2016. However, according to Article 32 of the Law titled 'Effectiveness of the Law,' the 8th, 9th, 11th, 13th, 14th, 15th, 16th, 17th, and 18th articles of this Law came into effect as of October 7, 2016.",
      kvkkText3:
        "The purpose of the Law is to regulate the processing of personal data to protect the fundamental rights and freedoms of individuals, especially the privacy of private life, and to set the principles and procedures to be followed by natural and legal persons processing personal data. This text informs and enlightens visitors by Delta Software in compliance with international privacy protection standards.",
      kvkkText4:
        "Delta Software has prepared this 'Privacy Policy' ('Policy') for the security of your data collected from digital services and mobile applications such as Kokpit, Gaboras, Planda, Avpass, Boyami, and IslamChannelGiving within Delta Software Internet Sites and Digital Platforms. We adopt an approach completely compatible with internationally accepted privacy protection standards to protect the privacy of our visitors, members, and users and to provide a secure experience in obtaining information.",
      kvkkText5:
        "The Company does not process Special Qualified Personal Data without the explicit consent of the data subject. However, Personal Data other than health and sexual life may be processed without the explicit consent of the data subject in cases foreseen in the laws. The Company processes Special Qualified Personal Data related to health and sexual life only under conditions stipulated by laws. The Company takes the necessary measures regarding the processing of Special Qualified Personal Data in accordance with the regulations of the Personal Data Protection Board.",
      kvkkText6:
        "Your personal data is processed by Delta Software for the following purposes: to ensure the delivery of products and services offered by the Company, to conduct the necessary operational activities within the company, to perform human resources management, to make commercial decisions, to provide legal security arising from relationships with individuals, and to fulfill legal obligations. Personal data may be processed without requiring explicit consent in the following cases: a) explicitly stipulated in the laws, b) necessity for the protection of life or physical integrity of the person or someone else who cannot express his consent due to actual impossibility or whose consent is not legally valid, c) direct relation to the conclusion or performance of a contract provided that it is directly related to the person who is a party to the contract, ç) necessary for the fulfillment of our legal obligations as the data controller, d) made public by the data subject, e) mandatory for the establishment, use, or protection of a right.",
      kvkkText7:
        "Delta Software has prepared this 'Privacy Policy' ('Policy') for the security of your data collected from digital services and mobile applications such as Kokpit, Gaboras, Planda, Avpass, Boyami, and IslamChannelGiving within Delta Software Internet Sites and Digital Platforms. We adopt an approach completely compatible with internationally accepted privacy protection standards to protect the privacy of our visitors, members, and users and to provide a secure experience in obtaining information.",
      kvkkText8:
        "Delta Software generally collects data of users through Internet Sites and Digital Platforms for the following purposes: to provide personalized services, improve the user experience, increase user satisfaction, communicate with users within the scope permitted by users, monitor the technical functions of Delta Software Internet Sites and Digital Platforms, use for advertising purposes to ensure that visitors, members, and users can continue to benefit from Delta Software Internet Sites and Digital Platforms for free, send publications, newsletters or notifications via electronic mail, answer questions and provide effective customer service, inform about new services, make direct marketing through Delta Software Internet and Digital Platforms or third-party Internet Sites, and perform various statistical evaluations, database creation, and market research.",
      kvkkText9:
        "We collect your personal data when you register or become a member of Delta Software Internet Sites and Digital Platforms, enter advertisements and marketing announcements, use our products and services, or contact us. It is possible to register or become a member through your accounts on social networks such as Facebook, Linkedin, Instagram, etc. If you prefer to register or become a member through social networks within the scope of this Policy, you authorize us to process, transfer, and store the data sent to us by these social networks. You can unlink the membership accounts you have matched at any time.",
      kvkkText10:
        "When registering on Delta Software Internet Pages and Digital Platforms, we collect information such as name, surname, email address, phone, address, gender, and resume when performing membership procedures on these platforms, entering advertisements and commercials, benefiting from our products and services, or contacting us. It is also possible to register or become a member through your accounts on social networks such as Facebook, Linkedin, Instagram, etc. When choosing to register or become a member via social networks within the scope of this Policy, you authorize us to process, transfer, and store the data sent to us by these social networks. We may collect your IP address information in the context of the registration or membership procedures you perform via social networks.",
      kvkkText11:
        "For the realization of the services we offer, we may request your credit card, billing address, and billing information. We may share such information with third parties for purposes such as completing shopping and payment transactions, verifying credit card information, or identity verification.",
      kvkkText12:
        "If you choose to disclose information or personal data that can be reached and seen by other visitors, users, or members (e.g., writing comments, voting, rating, giving recommendations, sharing photos, expressing your thoughts, etc.), such as your photo, name, surname, date of birth, or nickname, without explicit consent, we may use it for statistical, advertising, or promotional purposes without your explicit consent. We are not responsible if the personal data you disclose is used by third parties or other sites.",
      kvkkText13:
        "If you participate in contests, surveys, or draws organized by Delta Software Internet Sites and Digital Platforms, your personal data may be collected through Delta Software Internet Sites and Digital Platforms by Delta Software or authorized third parties. You will be informed when your personal information is collected by third parties.",
      kvkkText14:
        "To tailor the technologies we use to the needs of our users and visitors, address technical issues, and monitor our technical infrastructure, we may collect data about you. Through cookies or other programs and software, we may collect information about your digital behavior, such as click count, tab opening frequency, or topics you are interested in.",
      kvkkText15:
        "We may collect data that cannot be associated with you personally through the electronic devices you use (e.g., computers, laptops, tablets, smartphones, smart TVs, etc.), such as location information.",
      kvkkText16:
        "Your usage information related to Delta Software Internet Sites and Digital Platforms can be obtained using a technical communication file (Cookie) through cookies or other technologies. Technical communication files are small text files sent by a website to the user's browser to be stored in the main memory. They facilitate the use of the internet by storing the status and preferences of a website about the user. Technical communication files are not designed to receive data or personal information from the main memory or email. Most browsers are designed to accept technical communication files by default, but users can change their settings to reject them or receive a warning when a technical communication file is sent. Unless the member changes these settings, it is deemed that they have given consent to the use of cookies.",
      kvkkText17:
        "A cookie is a small text file that a website stores about your browsing data (PC, phone, or another device). The purpose of the Cookie Policy is to ensure the proper functioning of a website, improve user experience, develop and optimize the site, provide more suitable, interest-based advertising, offer an interesting and personalized website/application, and advertising portfolio for visitors. The cookie is also used to remember your preferences (language, country, etc.) during your visit to our site and on your future visits. Under this Policy, 'Cookies' used as the main information storage method by this website are used for the same purpose as 'Local Storage' in the browser. We do not store sensitive personal information such as your address, password, credit or debit card information in the Cookies we use.",
      kvkkText18:
        "Your collected personal data may be transferred to our business partners, suppliers, shareholders, legally authorized public institutions, and private individuals within the framework of the personal data processing conditions and purposes specified in Articles 8 and 9 of the Personal Data Protection Law.",
      kvkkText19:
        "Your personal data is collected by our company through different channels and for different legal reasons to carry out our commercial activities. The personal data collected for this legal reason may be processed and transferred for the purposes specified in clauses (b) and (c) of this article within the scope of the personal data processing conditions and purposes specified in Articles 5 and 6 of the Personal Data Protection Law.",

      kvkkText20:
        "<br /><br />- Learn whether personal data is being processed,<br />- Request information if personal data has been processed,<br />- Learn the purpose of processing personal data and whether they are used in accordance with that purpose,<br />- Know the third parties to whom personal data are transferred within or outside the country,<br />- Request the correction of personal data if it is incomplete or incorrectly processed, and request notification of this correction to third parties to whom personal data have been transferred within this scope,<br />- Object to the emergence of a result against the person by analyzing the processed data solely through automatic systems,<br />- In case of damage due to the unlawful processing of personal data, request the elimination of the damage.<br /><br />In accordance with Article 13, paragraph 1 of the Personal Data Protection Law, you can send your request regarding the exercise of the above-mentioned rights to our Company in writing or by other methods determined by the Personal Data Protection Board. As the Personal Data Protection Board has not determined any method at this stage, you must submit your application to our Company in writing in accordance with the Personal Data Protection Law. In this context, the channels and methods to submit your application for the exercise of the rights specified above are explained below. You can deliver the form to Delta Software Computer Services Industry and Trade Ltd. Şti., Delta Software Ikitelli OSB Neighborhood, YTÜ Teknopark Street, No:1, D:101, 34490 Başakşehir, in person with identity documents or send it via a notary or other methods specified in the Personal Data Protection Law or electronically signed to the relevant deltasoftech@icloud.com address.",
      kvkkText21:
        "We may provide links to websites, portals, or mobile applications belonging to individuals. However, we have no responsibility for the application of privacy policies on these sites or for third parties. Privacy policies within the scope of websites or mobile applications belonging to third parties may differ from this Policy.",
      kvkkText22:
        "In order to prevent the illegal processing and access of the personal data you share and to ensure the lawful storage of this data, we undertake to take the widest and appropriate preventive security measures by analyzing the current information and data values and risk situation in the light of technological developments, including secure databases, servers, firewalls (security software), and encryption of email information, and to notify the personal data owner and authorized administrative authorities in case of any information belonging to any user being stolen, deleted, or altered as a result of a cyber-attack by third parties in the shortest time expected from them.",
      kvkkText23:
        "If you specify that you do not want your data to be stored by applying to us, we undertake to delete data that is not necessary for the operation of our system and that we are not obliged to keep due to legal obligations or when the periods prescribed by law for storage have expired. We keep your personal information up-to-date and accurate with the support you provide. You can request through electronic mail sent via membership systems or “deltasoftech@icloud.com” email address.",
      kvkkText24:
        "You can opt out of the electronic mail or instant messaging services we send you for marketing and advertising purposes at any time using the methods provided in the messages.",
      kvkkText25:
        "We do not intentionally process the data of users under the age of 18. All our departments, including the real and legal persons we cooperate with, are aware of the sensitivity of your child's personal data and take the utmost care to protect this data. The sharing of your child's personal data with us is the responsibility of the parent or legal representative. If a parent realizes that their child has shared personal information with us without their consent, they can delete this personal information themselves through Delta Software Internet Sites and Digital Platforms or request it from us. We recommend that parents actively take a role in monitoring their children's online activities.",
      kvkkText26:
        "The provisions of this Policy may be changed or renewed at any time for any reason without notification. The Policy provisions amended will become effective on the date they are published on Delta Software Internet Sites and Digital Platforms. For this reason, we recommend that you follow the Policy updates.",
      NotFoundTitle: "Sorry, we couldn't find the result you're looking for.",
      NotFoundButton: "RETURN TO HOME PAGE.",

      kvkkFormTitle1: "GENERAL COMMENTS",
      kvkkFormTitle2: "Information required in the Application Form",
      kvkkFormTitle3: "A. Contact information of the Applicant:",
      kvkkFormTitle4: "B. Relationship of the Applicant with our company:",
      kvkkFormText1: `In the Law on the Protection of Personal Data No. 6698 ("KVKK"), individuals defined as data subjects ("Hereinafter referred to as the "Applicant") are granted the right to make certain requests regarding the processing of their personal data under Article 11 of the KVKK.
          <br />
          <br />
          In accordance with the first paragraph of Article 13 of the KVKK; The Applicant, who is the data controller, must submit requests regarding these rights to our Company in writing or by other methods determined by the Personal Data Protection Board ("Board").
          <br />
          <br />
          In this context, the applications to be made to our Company "in writing" can be submitted to us by taking the printout of this form;
          <br />
          <br />
          <ul>
            <li>In person by the Applicant,</li>
            <li>Through a notary,</li>
            <li>
              Signed with a "secure electronic signature" defined in the Electronic Signature Law No. 5070, and sent to the Company's registered email address, can be submitted.
            </li>
          </ul>`,
      kvkkFormText2: `In addition, after the other methods determined by the Board are announced, the ways in which applications will be received through these methods will be announced by our Company.
          <br />
          <br />
          Your applications submitted to us will be answered within thirty days from the date your request reaches us, in accordance with the second paragraph of Article 13 of the KVKK, depending on the nature of the request. Our responses will be sent to you in writing or electronically, in accordance with the provisions of Article 13 of the KVKK.
          <br />
          <br />
          (Your application will be concluded free of charge, but if the transaction requires an additional cost, a fee may be charged according to the tariff determined by the Board.)`,
      kvkkFormText3: `Please specify your request under the KVKK in detail:`,
      kvkkFormText4: `Please choose the method of notifying you of our response to your application:`,
      kvkkFormText5: `Note: (Choosing the email method will allow us to respond to you more quickly.)
          <br />
          <br />
          (In case of receipt by proxy, there must be a notarized power of attorney or authorization.)
          <br />
          This application form is prepared to determine your relationship with our Company, if any, and to determine your personal data processed by our Company, if any, in order to respond correctly and within the legal period to your relevant application.
          <br />
          For the purpose of eliminating legal risks that may arise from unlawful and unfair data sharing and, especially, ensuring the security of your personal data, our Company reserves the right to request additional documents and information (such as a copy of the ID card or driver's license, etc.) for identity and authorization.
          <br />
          Our Company does not accept responsibility for requests arising from incorrect information or unauthorized applications, and reserves the right to take any legal action it deems necessary.
          <br />
          <br />
          In accordance with the above-mentioned requests, I request that my application to your Company be evaluated under Article 13 of the Law and information be provided to me.
          <br />
          <br />
          I declare and undertake that the information and documents I have provided in this application form are correct and up-to-date and belong to me.
          <br />
          <br />
          I allow your Company to process the information and documents provided in this application form, limited to the evaluation, answering, delivery of my application, and identification of my identity and address, in accordance with Article 13 of Law No. 6698 on the Protection of Personal Data.
          <br />
          <br />
          <br />
          Name Surname of the Applicant (Personal Data Owner):
          <br />
          <br />
          <br />
          Signature:
          <br />
          <br />
          Application Date:
          <br />
          <br />
          Signature:`,

      kvkkFormTableTh1: "Application Method",
      kvkkFormTableTh2: "Address to Apply",
      kvkkFormTableTh3: "Information to be Specified in Application Submission",
      kvkkFormTableTr1Td1:
        "In-Person Application (The Applicant personally applying with an identity encouraging document)",
      kvkkFormTableTr1Td2: "Address of the Data Controller Institution",
      kvkkFormTableTr1Td3: ` "Request for Information within the Scope of the Personal Data Protection Law" will be written on the envelope.`,
      kvkkFormTableTr2Td1: "Registered Mail with Return Receipt",
      kvkkFormTableTr2Td2: "Address of the Data Controller Institution",
      kvkkFormTableTr2Td3: `"Request for Information within the Scope of the Personal Data Protection Law" will be written on the envelope.`,
      kvkkFormTableTr3Td1: "Notification through a Notary",
      kvkkFormTableTr3Td2: "Address of the Data Controller Institution",
      kvkkFormTableTr3Td3: `"Request for Information within the Scope of the Personal Data Protection Law" will be written on the envelope.`,
      kvkkFormTable2Th1: "Name:",
      kvkkFormTable2Th2: "Surname:",
      kvkkFormTable2Th3: "TC Identification Number:",
      kvkkFormTable2Th4: "Phone Number:",
      kvkkFormTable2Th5: "E-mail:",
      kvkkFormTable2Th6: "Address:",
      kvkkFormTable3Th1: "Request No",
      kvkkFormTable3Th2: "Subject of the Request",
      kvkkFormTable3Th3: "Legal Basis",
      kvkkFormTable3Th4: "Your Choice",
      kvkkFormTable3Tr1Td1:
        "I want to know if your company processes personal data about me",
      kvkkFormTable3Tr1Td2: "Personal Data Protection Law Article 11/1 (a)",
      kvkkFormTable3Tr2Td1:
        "If your company processes personal data about me, I request information about these data processing activities",
      kvkkFormTable3Tr2Td2: "Personal Data Protection Law Article 11/1 (b)",
      kvkkFormTable3Tr3Td1:
        "If your company processes personal data about me, I want to know the purpose of processing and whether it is used for this purpose",
      kvkkFormTable3Tr3Td2: "Personal Data Protection Law Article 11/1 (c)",
      kvkkFormTable3Tr4Td1:
        "If my Personal Data is transferred to third parties, I want to know these third parties.",
      kvkkFormTable3Tr4Td2: "Personal Data Protection Law Article 11/1 (ç)",
      kvkkFormTable3Tr5Td1:
        "I believe that my Personal Data has been processed incompletely or incorrectly, and I want them to be corrected",
      kvkkFormTable3Tr5Td2: "Personal Data Protection Law Article 11/1 (d)",
      kvkkFormTable3Tr6Td1:
        "Although my Personal Data has been processed in accordance with the law and other relevant laws, I believe that the reasons requiring processing have disappeared, and I request the deletion or destruction of my personal data within this framework",
      kvkkFormTable3Tr6Td2: "Personal Data Protection Law Article 11/1 (e)",
      kvkkFormTable3Tr7Td1:
        "I want my Personal Data, which I believe has been processed incorrectly or incompletely, to be corrected by third parties to whom it is transferred.",
      kvkkFormTable3Tr7Td2: "Personal Data Protection Law Article 11/1 (f)",
      kvkkFormTable3Tr8Td1:
        "Although my Personal Data has been processed in accordance with the law and other relevant laws, I believe that the reasons requiring processing have disappeared, and in this context, I request the deletion or destruction of my personal data by third parties.",
      kvkkFormTable3Tr8Td2: "Personal Data Protection Law Article 11/1 (g)",
      kvkkFormTable3Tr9Td1:
        "I believe that my Personal Data, processed exclusively by Automated Systems, has been analyzed, and I object to the result that has emerged from this analysis against me.",
      kvkkFormTable3Tr9Td2: "Personal Data Protection Law Article 11/1 (h)",
      kvkkFormTable3Tr10Td1:
        "I have suffered damage due to the unlawful processing of my Personal Data. I request compensation for this damage.",
      kvkkFormTable3Tr10Td2: "Personal Data Protection Law Article 11/1 (i)",
      kvkkFormTable3Tr11Td1: "Other Specify:",
      kvkkFormTable4Th1: "I want it to be sent to my address.",
      kvkkFormTable4Th2: "I want it to be sent to my e-mail address.",
      kvkkFormTable4Th3: "I want to receive it by hand.",
      homePageTitle: "Delta Software",
      notFoundPageTitle: "Page Not Found | Delta Software",
      aboutPageTitle: "About | Delta Software",
      blogPageTitle: "Blog | Delta Software",
      careerPageTitle: "Career | Delta Software",
      cookiePageTitle: "Cookie Policy | Delta Software",
      kvkkPageTitle: "Protection of Personal Data | Delta Software",
      kvkkFormPageTitle: "Protection of Personal Data Form | Delta Software",
      apiServicesError: "Please select a service",
      apiAgreementError: "Please confirm your acceptance",
      formSuccess: "Successfully submitted. We will get back to you.",
      formError: "An unknown error occurred. Please contact us.",
      fileSizeError: "File size cannot exceed 30 MB",
    },
  },

  tr: {
    translation: {
      homeBannerText: "BİZ DELTA YAZILIMIZ",
      homeBannerTitle: "YAZILIM DÜNYASININ EN YENİ YÜZÜ İLE TANIŞIN",
      servicesTitle: "SERVİSLERİMİZ",
      servicesText: `Delta Software olarak projelerinizi özenle ele alıyor ve en yüksek
      kalitede sonuçlar elde etmek için çalışıyoruz.`,
      servicesItemTitle1: `WEB UYGULAMA`,
      servicesItemText1: `Web uygulamaları konusundaki uzmanlığımızla, işletmenizin dijital dönüşümünü hızlandırıyoruz.`,
      servicesItemTitle2: `MOBİL UYGULAMA`,
      servicesItemText2: `Mobil dünyada profesyonelce yer almanız için hibrit ve native mobil uygulamalar geliştiriyoruz.`,
      servicesItemTitle3: `RPA YAZILIM`,
      servicesItemText3: `RPA yazılımı ile geliştirdiğimiz dijital robotlar sayesinde, zaman alıcı, rutin olarak yapılan iş ya da görevleri robotlara aktarıyoruz.`,
      servicesItemTitle4: `SİBER GÜVENLİK`,
      servicesItemText4: `Siber Güvenlik ekibimizle bilgisayarlarınızı, mobil cihazlarınızı ve verilerinizi kötü amaçlı saldırılardan koruyoruz.`,
      servicesItemTitle5: `GRAFİK VE VİDEO TASARIMI`,
      servicesItemText5: `Tasarım ekibimizle marka algınızı güçlendiren özgün dijital içerik çalışmalarına imza atıyoruz.`,
      servicesItemTitle6: `DİJİTAL PAZARLAMA`,
      servicesItemText6: `Ürünlerinizi daha geniş pazarlara ulaştırmanız için sizlere kaliteli dijital pazarlama çözümleri sunuyoruz.`,
      ourTechnologiesTitle: `KULLANDIĞIMIZ TEKNOLOJİLER`,
      ourTechnologiesText: `Projelerinizi, doğru zamanda, doğru araçlar ve güncel teknolojilerle size en uygun şekilde geliştirelim.`,
      projectDesingTitle: `PROJENİZİN YOLCULUĞU`,
      projectDesingText: `Günümüzün hızla dijitalleşen dünyasında, proje süreçlerinizi beraber
      profesyonelleştirelim.`,
      faqTitle: `SIKÇA SORULAN SORULAR`,
      faqText: `Şirketimiz ve servislerimiz hakkında soruların cevaplarını
      bulabilirsiniz.`,
      faqItemTitle1: `Delta Software Nedir?`,
      faqItemAnswer1: `Delta Software, 10 yılı aşkın bir süredir 3 farklı ülkede faaliyet gösteren, Özden Grup A.Ş’nin
      yazılım markasıdır. Web tasarım, pazarlama ve sosyal medya yönetiminden, web yazılım ve
      mobil uygulama odaklı olarak dijital hizmetler sağlar. Bu itibarla, markalarla iş birliği içerisine
      girerek yeni dijital dünyaya uyumlu geçişi sağlar.`,
      faqItemTitle2: `Delta Software Ne İşe Yarar?`,
      faqItemAnswer2: `Delta Software, markasını dijitale taşımak ve kurumsal bir kimliğe
      kavuşmak isteyen tüm markalara, güncel teknolojilerin gücüyle ;
      pazarlama; uygulama, program ve alt yapı gereksinimlerinin
      karşılanmasını sağlar.`,
      faqItemTitle3: `Delta Software Güvenli Mi?`,
      faqItemAnswer3: `Delta Software, İstanbul merkezli bir yazılım firması olarak, Mega Center İş Merkezi, C26 Blok
      Kat: 5 Bağımsız Bölümler 36-37-38-39-40-41 adreslerinde hizmet veren, güvenli bir şirkettir.
      Sektörde 10 yılı aşkın deneyimiyle müşterilerine kesintisiz şekilde hizmet sunmaktadır.`,
      faqItemTitle4: `Hangi Şirketler İnternet Sitesi Kurmak Zorunda?`,
      faqItemAnswer4: `TÜRK TİCARET KANUNU değişikliklerine göre şirketlere 14 ŞUBAT 2011
      tarihli: 27846 sayılı ve 6102 sayılı Resmî Gazete de web sitesi kurmak
      zorunluluğu getirilmiştir. İlgili kanunun yürürlüğe girmesiyle birlikte, belirli
      nitelikleri sağlayan anonim şirketlerin bağımsız kurum ve kuruluşlarca
      denetlenmesini öngören ve bu denetime tabi şirketlere internet sitesi
      açması zorunlu olarak kabul edilmiştir.`,
      faqItemTitle5: `Delta Software Mobil Uyumlu Web Sitesi Yapıyor Mu?`,
      faqItemAnswer5: `Delta Software, teknolojilerin olanaklarını düzenli olarak takip etmekte, geliştirdiği projelerde
      geleceğe yönelik olarak da planlamaları sağlamaktadır. Bu bağlamda yürüttüğü web
      projelerinde mobil uyumluluk (Responsive) özelliğini de sağlamaktadır.`,
      faqItemTitle6: `Delta Software Özel Yazılım Geliştirme Nedir?`,
      faqItemAnswer6: `Delta Software, markaların iş süreçlerindeki verimliliklerini arttırarak, kolayca entegre olacak
      özel yazılımlar ile süreçlerinizi profesyonelleştirmektedir. Özel yazılımlar doğrudan ilgili
      markaların ihtiyaç ve amaçlarına göre tasarlanmaktadır.`,
      faqItemTitle7: `Özel Yazılım Geliştirmenin Fiyatları Nelerdir?`,
      faqItemAnswer7: `Her firmanın iş akışı ve iş süreci farklıdır. Bu sebeple özel olarak geliştirilen
      yazılım hizmetlerinin ücretleri de değişkenlik göstermektedir. Bu noktada,
      Delta Software ile iletişime geçerek, markanıza uygun olarak geliştirilecek
      özel yazılımların detayları hakkında bize ulaşabilirsiniz.`,
      faqItemTitle8: `Delta Software Proje Sonrası Destek Veriyor Mu?`,
      faqItemAnswer8: `Delta Software hizmetlerini kesintisiz olarak sağlar. İş ortaklarıyla beraber geliştirdiği
      projelerin bakım ve teknik desteklerini sağlar.`,
      faqItemTitle9: `Delta Software Projeleri E-Ticareti Kapsıyor Mu?`,
      faqItemAnswer9: `Delta Software, E-Ticaret, Kurumsal Web Sitesi, Mobil Uygulama veya özel amaçlarla
      geliştirilmiş tüm yazılım hizmetlerini sağlamaktadır.`,
      faqItemTitle10: `Delta Software Dijital Pazarlama da Neler Sunuyor?`,
      faqItemAnswer10: `Delta Software, reklam ve pazarlama alanında yaptığı özel çalışmalarla, markanızın kurumsal
      kimliğini oluşturur, dijital dünyadaki görünürlüğünüzü arttırır, satış hacminizi yükseltir ve
      sektörde öne çıkmanızı sağlar`,
      projectFormTitle: `PROJENİZİ HAYATA GEÇİRMEK İÇİN HAZIRIZ`,
      projectFormText: `Sizi dinliyor, ihtiyaçlarınızı anlıyor ve markanıza en uygun projeleri
      geliştiriyoruz.`,
      projectFormInputName: "Ad Soyad*",
      projectFormInputEmail: "E-Mail Adresi",
      projectFormInputTel: "Telefon Numarası*",
      projectFormInputFile: "Varsa Proje Dosyası Yükleyin*",
      projectFormServices: "Hizmetler",
      projectFormCheckboxWeb: "Web Uygulama",
      projectFormCheckboxMobile: "Mobil Uygulama",
      projectFormCheckboxRPA: "RPA Yazılım",
      projectFormCheckboxCyber: "Siber Güvenlik",
      projectFormCheckboxGraphic: "Grafik ve Video Tasarımı",
      projectFormCheckboxDigital: "Dijital Pazarlama",
      projectFormDetail: "Detay",
      projectFormTextArea: "Talebiniz Hakkında Detaylı Bilgi Veriniz.",
      projectFormAgreement: `Kişisel verileriniz, Aydınlatma Metni kapsamında işlenmektedir.
      Formu doldurarak Aydınlatma Metni'ni , Gizlilik Politikası ve
      Çerez Politikası'nı okuduğunuzu ve kabul ettiğinizi
      onaylıyorsunuz.`,
      projectFormButton: "BAŞVURU YAP",
      headerStartProjectButton: "Projeni Başlat",
      headerMenuAbout: "HAKKIMIZDA",
      headerMenuServices: "HİZMETLER",
      headerMenuBlog: "BLOG",
      headerMenuCareer: "KARİYER",
      headerMenuContact: "İLETİŞİM",
      headerMenuProjectStart: "PROJENİ BAŞLAT",
      headerMenuSocialMedia: "SOSYAL MEDYA",
      headerMenuSocialLanguage: "DİL",
      headerMenuSocialLanguageEnglish: "ENGLISH",
      headerMenuSocialLanguageTurkish: "TÜRKÇE",
      footerContact: "İLETİŞİM",
      footerInstitutional: "KURUMSAL",
      footerServices: "HİZMETLER",
      footerContactNow: "İLETİŞİMDE KALIN",
      footerMenuInstitutional1: "Hakkımızda",
      footerMenuInstitutional2: "Açık Pozisyonlar",
      footerMenuInstitutional3: "Gizlilik Politikası",
      footerMenuInstitutional4: "Çerez Politikası",
      footerMenuInstitutional5: "Kişisel Veri Başvuru Formu",
      footerMenuServices1: "Web Uygulama",
      footerMenuServices2: "Mobil Uygulama",
      footerMenuServices3: "RPA Yazılım",
      footerMenuServices4: "Siber Güvenlik",
      footerMenuServices5: "Grafik ve Video Tasarımı",
      footerMenuServices6: "Dijital Marketing",
      footerMenuContactNowText: "En son haberler ve promosyonlar için kaydolun",
      footerMenuContactNowInput: "E-Mail Adresin",
      aboutTitle: "DELTA SOFTWARE Kimdir?",
      aboutText:
        "2017 yılında Türkiye'nin yazılım evi olma hedefiyle kurulmuş bir yazılım şirketiyiz. Yıllar geçtikçe büyüyen kadromuz ve kurduğumuz ortaklıklarla hedefimize ulaşma yolunda ilerlemekteyiz. Fikirleriyle bize gelen müşterilerimize proje yönetiminden UI/UX tasarımına kadar kapsamlı Web/Mobil Uygulama geliştirme hizmetleri sağlıyoruz; DevOps, Dynatrace ve AWS konusunda deneyimli ekibimizle danışmanlık hizmetleri sunuyoruz.",
      aboutWhoAreWe: "Biz Kimiz",
      aboutWhoAreWeTitle: "Takımımız, Projelerimiz ve Tecrübemiz",
      aboutWhoAreWeText:
        "2017 yılında Türkiye'nin yazılım evi olma hedefiyle kurulmuş bir yazılım şirketiyiz. Yıllar geçtikçe büyüyen kadromuz ve kurduğumuz ortaklıklarla hedefimize ulaşma yolunda ilerlemekteyiz. Fikirleriyle bize gelen müşterilerimize proje yönetiminden UI/UX tasarımına kadar kapsamlı Web/Mobil Uygulama geliştirme hizmetleri sağlıyoruz; DevOps, Dynatrace ve AWS konusunda deneyimli ekibimizle danışmanlık hizmetleri sunuyoruz.",
      aboutWhoAreWeBox1: "TAKIM ÜYESİ",
      aboutWhoAreWeBox2: "TAMAMLANAN PROJE",
      aboutWhoAreWeBox3: "FARKLI ÜLKEDE HİZMET",
      aboutWhoAreWeBox4: "SEKTÖR TECRÜBESİ",
      aboutWhoAreWeYear: "YIL",
      aboutOurVision: "Vizyonumuz & Misyonumuz",
      aboutOurVisionTitle:
        "Keşfediyoruz, Geliştiriyoruz, Geleceğin Teknolojisine Adım Atıyoruz!",
      aboutOurVisionText:
        "Delta Software olarak, iş birliği yaptığımız markaların dijital dönüşümlerini hayata geçiriyor, onları dijital geleceğe hazırlıyoruz. Teknolojik altyapımız ve gelişen teknolojileri yakından takip eden deneyimli ekibimiz ile ihtiyacınız olan dijital dönüşüm stratejinizi belirleyerek planlamalar yapıyoruz. Marka kimliğinizi öne çıkartacak yenilikçi çözümler ve yaratıcı fikirlerimizle sonuç odaklı işler üreterek memnuniyetinizi garantiliyoruz. Bu süreçte markanızın tüm dijital süreçlerini tek bir çatı altında toplayacak profesyonel bir iş ortaklığı için biz hazırız.",
      soonText: "YAKINDA HİZMETİNİZDE!",
      CookieTitle: "ÇEREZ POLİTİKASI",
      CookieSubTitle1: "Çerezler Vasıtasıyla İşlenen Kişisel Verileriniz",
      CookieSubTitle2: "Çerezlerin Saklanması ve Erişilmesi",
      CookieSubTitle3: "Çerezleri Kontrol Etme",
      CookieText1: `KVK Kanunundan doğan aydınlatma yükümlülüğü kapsamında sizleri, işbu http://deltasoftware.tech/ Çerez Politikası (“Çerez Politikası”) ile işlenen kişisel verileriniz hakkında bilgilendirmek isteriz.Günümüzde neredeyse her web sitesi çerez kullanmaktadır. Çerezler, web sunucusu tarafından tarayıcınız aracılığı ile bilgisayarınıza yerleştirilen küçük veri dosyalarıdır. Tarayıcınız ve sunucu arasında bir bağlantı sağlandığında site, çerezler aracılığıyla sizi tanır. Dolayısıyla, Sitemizi ziyaret etmenizle kullandığımız çerezler, cihazınızda site ziyaretinize ilişkin bilgi saklanmasını ve bu bilgilerin sonraki ziyaretleriniz sırasında kullanılmasını mümkün kılarak size kolaylık sağlamaktadır.`,
      CookieText2: `Çerezler vasıtasıyla İnternet Sitesi üzerindeki kullanım ve ziyaret alışkanlıklarınız, oturum bilgileriniz, IP adresiniz, veriler ve geçmiş işlemleriniz işlenir ve kullanılır.`,
      CookieText3: `Sitemizde sunulan içerik, erişim adresindeki alan adı dışında başka alan adları üzerinden de sağlanmaktadır. Bu gibi durumda her bir alan adı kendine münhasır üçüncü taraf çerezler yaratmaktadır.`,
      CookieText4: `Sitemizde kullandığımız çerezler ile sizlere daha verimli ve işlevsel bir internet deneyimi sunmayı amaçlamaktayız. Bu üçüncü kişi hizmet sağlayıcılar ile Şirketimiz arasında KVK mevzuatı uyarınca KVK Gizlilik Sözleşmesi akdedilmiş olup bu kişiler tarafından Şirketimiz adına elde edilen kişisel verilerinizin güvenliği garanti altına alınmıştır.`,
      CookieText5: `Sitemiz tarafından oluşturulan çerezler Sitemize erişim için kullandığınız internet sitesi tarayıcınız tarafından bilgisayarınızda saklanmaktadır. Bu çerezlerin içerdiği bilgilere sadece çerezi oluşturan alan adı http://deltasoftware.tech/ altında sunulan Sitemiz tarafından ve aynı tarayıcıyı kullandığınız takdirde uzaktan erişim mümkündür.`,
      CookieText6: `Genel olarak internet tarayıcıları, çerezleri otomatik olarak kabul edecek şekilde ön tanımlıdır. Tarayıcılar, çerezleri engelleyecek veya cihaza çerez gönderildiğinde kullanıcıya uyarı verecek şekilde ayarlanabilir. Çerezleri yönetmek tarayıcıdan tarayıcıya farklılık gösterdiğinden ayrıntılı bilgi almak için tarayıcının yardım menüsüne bakılabilir.`,
      CookieText7: `Diğer yandan, tarayıcınız üzerinden cihazınızda kaydedilen çerezleri silebilir veya bu çerezlerin listesini ve değerlerini görebilir ve takip edebilirsiniz.`,
      kvkkMainTitle: `GENEL AÇIKLAMALAR`,
      kvkkSecondTitle1: `1. Kişisel Verilerin Korunması ve İşlenmesi Politikasının Kapsamı`,
      kvkkSecondTitle2: `2- Verilerinizi İşleme Nedenlerimiz`,
      kvkkSecondTitle3: `3. Size Ait Ne Tür Verileri Toplamaktayız?`,
      kvkkSecondTitle4: `4. Delta Software İnternet Siteleri ve Dijital Platformlarında hangi
      amaçla bilgileriniz toplanmaktadır?`,
      kvkkSecondTitle5: `5. Hangi Araçlarla ve Ne Zaman Bilgilerinizi Topluyoruz?`,
      kvkkSecondTitle6: `6.Size Ait Kişisel Verileri Nasıl Topluyoruz?`,
      kvkkSecondTitle7: `Çerez Politikası`,
      kvkkSecondTitle8: `7. İşlenen Kişisel Verilerin Kimlere ve Hangi Amaçla Aktarılabileceği`,
      kvkkSecondTitle9: `8. Kişisel Veri Toplamanın Yöntemi ve Hukuki Sebebi`,
      kvkkSecondTitle10: `9. Kişisel Veri Sahibinin KVK Kanunu’nun 11. maddesinde Sayılan
      Hakları`,
      kvkkSecondTitle11: ` 10. Delta Software İnternet Siteleri ve Dijital Platformlar Üzerinden
      3. Kişi İnternet Siteleri veya Mobil Uygulamalara Verilen Linkler`,
      kvkkSecondTitle12: `11. Bilgilerinizi Nasıl Koruyoruz?`,
      kvkkSecondTitle13: `12. Bilgilerimi Nasıl Yönetirim?`,
      kvkkSecondTitle14: `13. Elektronik iletileri nasıl engellerim?`,
      kvkkSecondTitle15: `14. Çocuklar`,
      kvkkSecondTitle16: `15. Politika’nın Güncellenmesi`,
      kvkkSubTitle1: `Bilgilendirme`,
      kvkkSubTitle2: `2.3. Özel Nitelikli Kişisel Verilerin İşlenme Şartları`,
      kvkkSubTitle3: `6.1 Üyelik/ Kayıt`,
      kvkkSubTitle4: `6.2 Kredi Kartı Bilgileri`,
      kvkkSubTitle5: `6.3 Tarafınızca Üretilen veya kamuya Açık Olan İçerikler (Yorumlar,
        oylamalar vb.)`,
      kvkkSubTitle6: `6.4. Yarışmalar, Çekilişler, Anketler Kapsamında Toplanan Kişisel
      Bilgileriniz`,
      kvkkSubTitle7: `6.5. Kişisel Veriniz Olmayan ve Otomatik Olarak Topladığımız Bilgiler`,
      kvkkSubTitle8: `6.6.Cihaz Bilgisi`,
      kvkkSubTitle9: `6.7. Çerezler, Beacon Üzerinden Toplanan Veriler`,
      kvkkText1: ` Delta Software ; internet sitesi, mobil uygulamalar ve diğer dijital
      platformlar üzerinden sağladığı hizmetler kapsamında ziyaretçilerinin,
      üyelerinin ve kullanıcılarının gizliliğine değer vermekte olup,
      paylaşılan kişisel bilgilerin gizliliği ve bilgilerin güvenliği ile
      ilgili ziyaretçilerin, üyelerin ve kullanıcıların kaygılarını saygıyla
      karşılamaktadır. Bu kapsamda 6698 sayılı Kişisel Verilerin Korunması
      Kanunu (“Kanun”, “KVKK”) ve ilgili diğer mevzuatlar dâhilinde sizleri
      bilgilendirmek ve aydınlatmak amacıyla işbu metin hazırlanmıştır.`,
      kvkkText2: `6698 Sayılı Kişisel Verilerin Korunması Kanunu 24 Mart 2016 tarihinde
      kabul edilmiş olup, 7 Nisan 2016 tarihinde Resmi Gazete’de
      yayımlanarak yürürlüğe girmiştir. Bununla birlikte, Kanun’un Yürürlük
      başlıklı 32.maddesine göre, bu Kanunun; 8 inci, 9 uncu, 11 inci, 13
      üncü, 14 üncü, 15 inci, 16 ncı, 17 nci ve 18 inci maddeleri 7 Ekim
      2016 tarihi itibari ile yürürlük kazanmıştır.`,
      kvkkText3: `Kanun; Kişisel verilerin işlenmesinde başta özel hayatın gizliliği
      olmak üzere kişilerin temel hak ve özgürlüklerini korumak ve kişisel
      verileri işleyen gerçek ve tüzel kişilerin yükümlülükleri ile
      uyacakları usul ve esasları düzenlemek amacıyla kabul edilmiştir. İşbu
      metin ile Delta Software tarafından ziyaretçiler bilgilendirilmekte ve
      aydınlatılmakla birlikte “Kişisel Verilerin Korunması ve Kişisel Veri
      Paylaşım İzni” ziyaretçilerin bilgi ve incelemesine sunulmaktadır`,
      kvkkText4: `Delta Software içerisinde yer alan Kokpit,Gaboras,Planda,Avpass,Boyami
      ve İslamChannelGiving dijital hizmetler ve mobil uygulamalardan
      alınan(birlikte “Delta Software İnternet Siteleri ve Dijital
      Platformlar” olarak anılacaktır.) kapsamında söz konusu site ve
      portallar üzerinden toplanan verileriniz ve bu verilerinizin güvenliği
      için işbu “Gizlilik Politikası” (“Politika”) ’nı
      hazırladık.Ziyaretçilerimizin, üyelerimizin ve kullanıcılarımızın
      gizliliğini korumak ve bilgi temininde güvenli bir deneyim sağlamak ve
      kişisel bilgilerin kullanılmasında, tamamen uluslararası alanda kabul
      edilen mahremiyet koruma standartlarıyla uyumlu bir anlayış
      benimsemekteyiz.`,
      kvkkText5: `Şirket, Özel Nitelikli Kişisel Veriler’i, ilgilinin açık rızası
      olmaksızın işlemez. Ancak sağlık ve cinsel hayat dışındaki Kişisel
      Veriler, kanunlarda öngörülen hallerde ilgili kişinin açık rıza
      aranmaksızın işlenebilecektir. Sağlık ve cinsel hayata ilişkin Kişisel
      Veriler, Şirket tarafından ancak kamu sağlığının korunması, koruyucu
      hekimlik, tıbbı teşhis ve tedavi ve bakım hizmetlerinin yürütülmesi,
      sağlık hizmetleri ile finansmanının planlanması ve yönetimi amacıyla,
      sır saklama yükümlülüğü altında bulunduğumuz koşullarda ilgili kişinin
      açık rızası aranmaksızın işlenir. Şirket Özel Nitelikteki Kişisel
      Veriler’in işlenmesinde Kurul tarafından belirlenen yeterli önlemlerin
      alınması konusunda gerekli işlemleri yürütmektedir.`,
      kvkkText6: `Kişisel verileriniz, şirketimiz tarafından sunulan ürün ve hizmetlerin
      tarafınıza sunulabilmesini temin için şirket içerisinde gerekli
      operasyonel faaliyetlerin yürütülmesi, siz müşterilerimize tüketim ve
      alım motivasyonunuza uygun ürün ve hizmetlerin önerilebilmesi için
      gerekli çalışmaların ilgili iş birimi ve iş ortakları ile yapılması,
      insan kaynağı yönetiminin Şirketimiz tarafından sağlanarak gerçek
      kişilerin haklarının temini, Şirketimiz tarafından ticari kararların
      verilmesi, uygulanması ve gerçekleştirilmesi konusunda gerekli
      adımların atılması, iş ilişkisi kurduğumuz gerçek kişilerin ve
      Şirketimizin bu ilişkilerden kaynaklı hukuki emniyetinin sağlanması ve
      bunlarla sınırlı olmamak kaydıyla benzer amaçlarla Kanun’un 5 ve 6.
      maddeleri uyarınca işlenmektedir. Kişisel Verileriniz, Veri Sorumlusu
      sıfatıyla Delta Software tarafından, aşağıdaki hallerde ayrıca bir
      açık rızanız aranmaksızın işlenebilecektir: a) Kanunlarda açıkça
      öngörülmesi; b) Fiili imkânsızlık nedeniyle rızasını açıklayamayacak
      durumda bulunan veya rızasına hukuki geçerlilik tanınmayan kişinin
      kendisinin ya da bir başkasının hayatı veya beden bütünlüğünün
      korunması için zorunlu olması. c) Sözleşmenin ifasıyla doğrudan
      doğruya ilgili olması kaydıyla, sözleşmenin taraflarına ait kişisel
      verilerin işlenmesinin gerekli olması. ç) Veri sorumlusu olarak hukuki
      yükümlülüklerimizin yerine getirebilmesi için zorunlu olması; d)
      İlgili kişinin kendisi tarafından alenileştirilmiş olması. e) Bir
      hakkın tesisi, kullanılması veya korunması için veri işlemenin zorunlu
      olması f) İlgili kişinin temel hak ve özgürlüklerine zarar vermemek
      kaydıyla, veri sorumlusunun meşru menfaatleri için veri işlenmesinin
      zorunlu olması, şartlarından herhangi birisine dayanarak, aşağıda
      belirteceğimiz amaçlarla kullanılabilecektir.`,
      kvkkText7: `Delta Software içerisinde yer alan Kokpit,Gaboras,Planda,Avpass,Boyami
      ve İslamChannelGiving dijital hizmetler ve mobil uygulamalardan
      alınan(birlikte “Delta Software İnternet Siteleri ve Dijital
      Platformlar” olarak anılacaktır.) kapsamında söz konusu site ve
      portallar üzerinden toplanan verileriniz ve bu verilerinizin güvenliği
      için işbu “Gizlilik Politikası” (“Politika”) ’nı
      hazırladık.Ziyaretçilerimizin, üyelerimizin ve kullanıcılarımızın
      gizliliğini korumak ve bilgi temininde güvenli bir deneyim sağlamak ve
      kişisel bilgilerin kullanılmasında, tamamen uluslararası alanda kabul
      edilen mahremiyet koruma standartlarıyla uyumlu bir anlayış
      benimsemekteyiz.`,
      kvkkText8: `Delta Software İnternet Siteleri ve Dijital Platformlar üzerinden
      kullanıcılarımıza ait verileri genel olarak aşağıdaki amaçlar ile
      toplamaktayız:· Kullanıcılarımıza kişiselleştirilmiş hizmetler sunmak
      ve kullanıcı tecrübesini daha kaliteli hale getirerek kullanıcı
      memnuniyetini arttırmak,· Üyelerimizin, kullanıcılarımızın veya
      ziyaretçilerimizin bize izin verdiği ölçüde onlarla iletişime geçmek,
      hizmet ve kampanyalarımızdan haberdar etmek,·Delta Software İnternet
      Siteleri ve Dijital Platformları’nın teknik açıdan fonksiyonlarını
      izlemek ve gerektiği gibi çalışmasını sağlamak,· Ziyaretçilerimiz,
      üyelerimiz ve kullanıcılarımızın için Delta Software İnternet Siteleri
      ve Dijital Platformları’ndan ücretsiz olarak yararlanmasını
      sürdürebilmesi için reklam satış amaçları ile kullanmak,· Size
      yayınlarımızı göndermek,· Elektronik posta ile bülten göndermek ya da
      bildirimler de bulunmak,· Sorularınızı cevaplamak ve etkin bir müşteri
      hizmeti sunmak,· Size yeni hizmetlerimiz hakkında bilgi vermek,· Delta
      Software İnternet ve Dijital Platformları veya iş birliği içinde
      olduğu kişiler tarafından doğrudan pazarlama yapmak,· Kullanıcının
      kimliğini ifşa etmeden çeşitli istatistiksel değerlendirmeler, veri
      tabanı oluşturma ve pazar araştırmalarında kullanmak,· Sistemle ilgili
      sorunların tanımlanması ve Delta Software İnternet ve Dijital
      Platformları içerisinde çıkabilecek sorunları ivedilikle gidermek,· IP
      adresleri, kullanıcıları, ziyaretçileri veya üyeleri genel bir şekilde
      tanımlamak ve kapsamlı demografik bilgi toplamak.Delta Software
      İnternet Siteleri ve Dijital Platformları işbu Politika dışında başka
      verilerin güvenliği,gizliliği,toplanması vb. hususlarda başka
      politikalar veya prosedürler benimsemiş olabilir.Bu nedenle diğer
      politika ve hukuki metinleri de incelemenizi tavsiye ederiz.`,
      kvkkText9: `Size ait kişisel verilerinizi;· Delta Software İnternet Siteleri ve
      Dijital Platformları’na üye veya kayıt olduğunuzda,. Çerezler ve konum
      takipli reklam hizmet uygulamaları (beacon),. Reklam veya pazarlama
      ilanlarımıza giriş yaptığınızda,. Delta Software İnternet Siteleri ve
      Dijital Platformlarını kullandığınızda,. Kampanya veya
      promosyonlarımız ile yarışma, anket, çekilişe vb.
      katıldığınızda,Toplamaktayız`,
      kvkkText10: ` Delta Software İnternet Sayfaları ve Dijital Platformlarına kayıt
      olurken isim, soy isim, elektronik posta adresi, telefon, adres,
      cinsiyet ve özgeçmiş bilgisi gibi bilgilerinizi, söz konusu
      platformlarda üyelik işlemlerinizi gerçekleştirirken, ilan ve reklam
      verirken, ürün ve hizmetlerimizden yararlanırken veya bizlerle
      iletişime geçerken paylaşmaktasınız.Delta Software İnternet Sayfaları
      ve Dijital Platformlarına Facebook, Linkedin, Instagram v.b. sosyal
      ağlarda yer alan hesaplarınız aracılığıyla da isim, soy isim, doğum
      tarihi, elektronik posta adresi vb. bilgileri girmeden kayıt veya üye
      olmak mümkündür. İşbu Politika kapsamında sosyal ağlar üzerinden
      hizmetlerimize kayıt veya üye olmayı tercih etmeniz halinde, söz
      konusu sosyal ağlar tarafından tarafımıza gönderilen verileri işleme,
      aktarma, saklama yetkilerini tarafımıza vermiş olursunuz.Sosyal ağlar
      üzerinden yaptığınız kayıt veya üyelik işlemleri kapsamında IP adresi
      bilgilerinizi toplayabiliriz. Sosyal ağlar üzerinden eşleştirdiğiniz
      üyelik hesaplarınızı dilediğiniz zaman söz konusu eşleştirmeyi
      kaldırabilirsiniz`,
      kvkkText11: `Sunduğumuz hizmetlerin gerçekleştirilmesi için sizden kredi kartı,
      fatura adres ve fatura bilgilerini talep edebiliriz. Söz konusu
      bilgiler alışveriş ve ödeme işlemlerinin tamamlanması, kredi kart
      bilgilerinin doğrulanması veya kimlik doğrulaması gibi neden veya
      amaçlar ile 3. kişiler ile paylaşabiliriz`,
      kvkkText12: `Kamuya ifşa etmeyi tercih ettiğiniz (örn. yorum yazmak, oy vermek,
        puanlama yapmak, tavsiye vermek fotoğraf paylaşmak, düşüncelerinizi
        açıklamak gibi) ve diğer ziyaretçi, kullanıcı veya üyelerin
        ulaşabileceği ve görebileceği şekilde bilgi veya kişisel verilerinizi
        paylaşmanız durumunda (örn. fotoğrafınız, isminiz, soyisminiz, doğum
        tarihiniz, takma isminiz (nickname) tarafınızdan ifşa edilmiş olarak
        kabul edilir ve açık rızanız olmadan istatistiki, reklam veya
        promosyonel amaçlarla kullanabiliriz. İfşa ettiğiniz kişisel
        verilerinizin 3. kişiler veya başka siteler tarafından kullanıldığı
        hallerde herhangi bir sorumluluğumuz bulunmamaktadır.`,
      kvkkText13: `Delta Software İnternet Siteleri ve Dijital Platformları tarafından
      düzenlenen yarışmalar, anketler veya çekilişlere katılım göstermeniz
      halinde kişisel verileriniz Delta Software veya yetkilendirdiği 3.
      kişiler tarafından Delta Software İnternet Siteleri ve Dijital
      Platformlar üzerinden toplanabilir. Kişisel bilgilerinizin 3. kişiler
      tarafından toplandığı durumlarda, bu durum size bildirilir.`,
      kvkkText14: `Kullandığımız teknolojileri kullanıcı, üye ve ziyaretçilerimizin
      ihtiyaçlarına uyumlu hale getirmek, teknik aksaklıkları gidermek,
      teknik altyapımızı denetlemek amacıyla sizin hakkınızda veri
      toplayabiliriz. Çerezler veya diğer program ve yazımlarımız
      vasıtasıyla site içi kullanımlarınıza bağlı olarak tıklama sayınız,
      sekmeleri açma sıklığınız veya ilgi gösterdiğiniz başlıklara ilişkin
      dijital ortamdaki davranışlarınız hakkında bilgi toplayabiliriz`,
      kvkkText15: `Kullandığınız elektronik cihazlar (bilgisayar, diz üstü bilgisayar,
        tabletler, akıllı telefonlar, akıllı televizyonlar vb.)üzerinden
        cihazınıza ait sizle eşleştirilemeyecek veriler (örn. konum bilgisi
        gibi) toplayabiliriz.`,
      kvkkText16: `Delta Software İnternet Siteleri ve Dijital Platformlarına ait
      kullanım bilgileriniz, teknik bir iletişim dosyasını (Çerez-Cookie)
      kullanarak elde edebilir. Bahsi geçen teknik iletişim dosyaları, ana
      bellekte saklanmak üzere bir internet sitesinin, kullanıcının
      tarayıcısına (browser) gönderdiği küçük metin dosyalarıdır. Teknik
      iletişim dosyası bir internet sitesi hakkında durum ve tercihleri
      saklayarak internetin kullanımını kolaylaştırır. Teknik iletişim
      dosyası, Delta Software İnternet Siteleri ve Dijital Platformlarını
      kaç kişinin kullandığını, bir kişinin Delta Software İnternet Siteleri
      ve Dijital Platformların hangi amaçla, kaç kez ziyaret edildiğini ve
      ne kadar kaldığı hakkında istatistiksel bilgileri elde etmek ve
      kullanıcılar için özel tasarlanmış kullanıcı sayfalarından dinamik
      olarak reklam ve içerik üretilmesine yardımcı olur. Teknik iletişim
      dosyası, ana bellekten veya elektronik postasından veri veya başkaca
      herhangi bir kişisel bilgi almak için tasarlanmamıştır. Tarayıcıların
      pek çoğu başta teknik iletişim dosyasını kabul eder biçimde
      tasarlanmıştır, ancak kullanıcılar dilerse teknik iletişim dosyasının
      gelmemesi veya teknik iletişim dosyasının gönderildiğinde ikaz
      verilmesini sağlayacak biçimde ayarları değiştirebilirler. Üye, işbu
      ayarları değiştirmediği sürece çerez kullanımına açık onay verdiği
      kabul edilir.Çerezler ve beacon gibi teknolojileri kullanabilir ve
      bunları cihazınıza yerleştirebiliriz. Çerez vb. teknoloji ve paketleri
      kabul etmemeniz halinde Delta Software İnternet Siteleri ve Dijital
      Platformları beklediğiniz fonksiyonları gerçekleştirmeyebilir veya
      aksaklıklar oluşabilir.Çerezler üzerinden alınan verileri 3. kişilerle
      reklam amaçlarıyla paylaşabiliriz.`,
      kvkkText17: `Çerez, bir web sitesinin, o sitede dolaşımınıza ilişkin verileri; PC,
      telefon ya da başka bir cihazınızda depoladığı küçük bir metin
      dosyasıdır.Çerez Politikası’nın Amacı; bir internet sitesinin düzgün
      bir şekilde çalışması, kullanıcı deneyiminin iyileştirilmesi, sitenin
      geliştirilmesi ve optimize edilmesi, daha uygun, ilgiye dayalı reklam
      sunma, ziyaretçiler için ilgi çekici ve kişiselleştirilmiş bir web
      sitesi/uygulama ve reklam portföyü sunmaktır. Çerez aynı zamanda
      belirttiğiniz tercihlerinizi (dil, ülke, vb.) sitemizi ziyaretiniz
      sırasında ve gelecekteki ziyaretlerinizde hatırlamak için
      kullanılır.İşbu Politika uyarınca, bu web sitesi tarafından ana bilgi
      depolama yöntemi olarak kullanılan “Çerezler” ile tarayıcının “Yerel
      Depolama” alanı aynı amaç için kullanılır. Bu bölümde yer verilen tüm
      bilgiler aynı zamanda anılan “Yerel Depolama” için uygulanır.
      Kullandığımız Çerezlerde adresiniz, şifreniz, kredi ya da banka kartı
      bilgileriniz gibi özel nitelikli kişisel bilgileri depolamıyoruz.Web
      sitesinde Çerez kullanımından kaçınma hakkıYukarıda açıklanan
      sınırlamaları da dikkate alarak bu web sitesinde Çerez kullanmak
      istemiyorsanız, öncelikle tarayıcınızda Çerez kullanımını devre dışı
      bırakmanız, ardından bu web sitesiyle ilişkili olarak tarayıcınızda
      kaydedilmiş Çerezleri silmeniz gerekmektedir. Çerezlerin kullanımını
      önlemek için bu seçeneği istediğiniz zaman kullanabilirsiniz.Çerez
      kullanımını devre dışı bırakmaYukarıda belirtilen adımları izleyerek
      istediğiniz bir anda tarayıcınızın ayarlarını değiştirip bu web
      sitesinde kullanılan Çerezleri kısıtlayabilir, engelleyebilir ya da
      silebilirsiniz. Her tarayıcıda ayarlar farklı olsa da Çerezler
      genellikle “Tercihler” ya da “Araçlar” menüsünde yer alır.
      Tarayıcınızda Çerezleri ayarlamak hakkında daha fazla bilgi için
      tarayıcının “Yardım” menüsüne bakın.`,
      kvkkText18: `Toplanan kişisel verileriniz; Şirketimiz ve Şirketimizle iş ilişkisi
      içerisinde olan kişilerin hukuki ve ticari güvenliğinin temini;
      Şirketimiz tarafından sunulan ürün ve hizmetlerden sizleri
      faydalandırmak için gerekli çalışmaların iş birimlerimiz tarafından
      yapılması; Şirketimiz tarafından sunulan ürün ve hizmetlerin sizlerin
      beğeni, kullanım alışkanlıkları ve ihtiyaçlarına göre özelleştirilerek
      sizlere önerilmesi Şirketimizin ticari ve iş stratejilerinin
      belirlenmesi ve uygulanması ile Şirketimizin insan kaynakları
      politikalarının yürütülmesinin temini amaçlarıyla iş ortaklarımıza,
      tedarikçilerimize, hissedarlarımıza, kanunen yetkili kamu kurumları ve
      özel kişilere, KVK Kanunu’nun 8. ve 9. maddelerinde belirtilen kişisel
      veri işleme şartları ve amaçları çerçevesinde aktarılabilecektir.`,
      kvkkText19: `Kişisel verileriniz Şirketimiz tarafından farklı kanallarla ve farklı
      hukuki sebeplere dayanarak ticari faaliyetlerimizi yürütmek amacıyla
      toplanmaktadır. Bu hukuki sebeple toplanan kişisel verileriniz KVK
      Kanunu’nun 5. ve 6. maddelerinde belirtilen kişisel veri işleme
      şartları ve amaçları kapsamında bu metnin (b) ve (c) maddelerinde
      belirtilen amaçlarla da işlenebilmekte ve aktarılabilmektedir.`,
      kvkkText20: `
      <br />
      <br />
      - Kişisel veri işlenip işlenmediğini öğrenme,
      <br />
      - Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,
      <br />
      - Kişisel verilerin işlenme amacını ve bunların amacına uygun
      kullanılıp kullanılmadığını öğrenme,
      <br />
      - Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü
      kişileri bilme,
      <br />
      - Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların
      düzeltilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerin
      aktarıldığı üçüncü kişilere bildirilmesini isteme,
      <br />
      - KVK Kanunu’nun ve ilgili diğer kanun hükümlerine uygun olarak
      işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan
      kalkması hâlinde kişisel verilerin silinmesini veya yok edilmesini
      isteme ve bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı
      üçüncü kişilere bildirilmesini isteme,
      <br />
      - İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz
      edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya
      çıkmasına itiraz etme,
      <br />
      - Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara
      uğraması hâlinde zararın giderilmesini talep etme haklarına sahiptir.
      <br />
      <br />
      KVK Kanunu’nun 13. maddesinin 1. fıkrası gereğince, yukarıda
      belirtilen haklarınızı kullanmak ile ilgili talebinizi, yazılı veya
      Kişisel Verileri Koruma Kurulu’nun belirlediği diğer yöntemlerle
      Şirketimize iletebilirsiniz. Kişisel Verileri Koruma Kurulu, şu
      aşamada herhangi bir yöntem belirlemediği için, başvurunuzu, KVK
      Kanunu gereğince, yazılı olarak Şirketimize iletmeniz gerekmektedir.
      Bu çerçevede Şirketimize KVK Kanunu’nun 11. maddesi kapsamında
      yapacağınız başvurularda yazılı olarak başvurunuzu ileteceğiniz
      kanallar ve usuller aşağıda açıklanmaktadır.Yukarıda belirtilen
      haklarınızı kullanmak için kimliğinizi tespit edici gerekli bilgiler
      ile KVK Kanunu’nun 11. maddesinde belirtilen haklardan kullanmayı
      talep ettiğiniz hakkınıza yönelik açıklamalarınızı içeren talebinizi;
      Delta Software Bilgisayar Hizmetleri San. Ve Tic. Ltd. Şti. Başvuru
      formu doldurarak, formun imzalı bir nüshasını Delta Software İkitelli
      OSB Mahallesi, YTÜ Teknopark Sokak, No:1, D:101, 34490 Başakşehir
      adresine kimliğinizi tespit edici belgeler ile bizzat elden
      iletebilir, noter kanalıyla veya KVK Kanunu’nda belirtilen diğer
      yöntemler ile gönderebilir veya ilgili deltasoftech@icloud.com
      adresine güvenli elektronik imzalı olarak iletebilirsiniz.`,
      kvkkText21: ` kişilere ait internet sitelerine, portallere veya mobil uygulamalara
      linkler verebiliriz. Fakat bu sitelerde yer alan veya 3. kişilerin
      tabi olduğu gizlilik politikalarının uygulanması hususunda hiçbir
      sorumluluğumuz bulunmamaktadır. 3. kişilere ait internet siteleri veya
      mobil uygulamalar kapsamındaki gizlilik politikalarının işbu
      Politika’dan farklı olabilir.`,
      kvkkText22: ` Paylaştığınız kişisel verilerinizin hukuka aykırı işlenmesini ve
      erişimini önlemek ve söz konusu verilerin hukuka uygun olarak
      saklanmasını sağlamak adına güvenli veri tabanları, sunucular,
      firewall’lar (güvenlik yazılımları) ve elektronik posta bilgilerinin
      şifrelenmesi dahil, güncel teknolojik gelişmeler ışığında kişisel
      verileriniz de dahil olmak üzere bilgilerinizi korumak için bilgi
      güvenliği politikalarıyla mevcut bilgi ve veri değerlerinin ve risk
      durumunun analizini yaparak en geniş ve uygun önleyici güvenlik
      önlemlerini almayı ve 3.kişiler tarafından siber saldırı gerçekleşmesi
      sonucunda herhangi bir kullanıcıya ait bilgilerin çalınması, sistemden
      silinmesi veya değiştirilmesi halinde işbu hususu kendisinden
      beklenebilecek en kısa süre zarfında kişisel veri sahibine ve yetkili
      idari makamlara bildirmeyi taahhüt etmekteyiz.`,
      kvkkText23: `Bize başvurarak verilerinizin saklanmasını istemediğinizi belirtmeniz
      halinde, sistemimizin işleyişi için gerekli olmayan ve kanuni
      yükümlülüğü gereği saklamak zorunda olmadığı veya kanunda saklanması
      için öngörülen sürelerin sona erdiği verileri silmeyi taahhüt
      ederiz.Bize vereceğiniz destek ile kişisel bilgileriniz teknolojik
      imkanlar ışığında güncel ve doğru şekilde tutarız. Bilgilerinizi
      üyelik sistemleri üzerinden veya “deltasoftech@icloud.com” e-mail
      adresi üzerinden göndereceğiniz elektronik posta ile talep
      edebilirsiniz.`,
      kvkkText24: ` Dilediğiniz anda pazarlama ve reklam amaçları ile size gönderdiğimiz
      elektronik posta veya anlık ileti hizmetlerinden size mesajlarda
      sağlanan yöntemler ile çıkabilirsiniz.`,
      kvkkText25: ` 18 yaşından küçük kullanıcıların verilerini kasten işlememekteyiz.
      işbirliği yaptığımız gerçek ve tüzel kişiler de dahil olmak üzere tüm
      departmanlarımız çocuklarınızın kişisel verilerinin hassasiyetinin
      farkında olup bu verilerin korunması hususunda en yüksek mertebede
      özen göstermekteyiz. Çocuğunuzun kişisel verilerinin bizimle
      paylaşılması veli veya yasal temsilcinin sorumluluğundadır.Eğer bir
      ebeveyn çocuğunun, kendi rızası olmadan kişisel bilgilerini bizimle
      paylaştığını fark eder ise bu kişisel bilgileri Delta Software
      İnternet Siteleri ve Dijital Platformları üzerinden kendisi silebilir
      veya bizden talep edebilir.Ebeveynlere çocuklarının online
      aktivitelerinin denetlenmesinde aktif bir rol üstlenmelerini tavsiye
      ederiz.`,
      kvkkText26: `İşbu Politika hükümleri her zaman herhangi bir sebepten dolayı
      bildirimde bulunmaksızın değiştirilebilir veyahut da yenilenebilir.
      Değişiklik yapılan Politika hükümleri Delta Software İnternet Siteleri
      ve Dijital Platformlarında yayınlandığı tarihte yürürlük kazanır. İşbu
      sebeple Politika güncellemelerini takip etmenizi önermekteyiz.`,
      NotFoundTitle: "Üzgünüz, aradığınız sonucu bulamadık.",
      NotFoundButton: "ANA SAYFAYA DÖN",
      kvkkFormTitle1: "GENEL AÇIKLAMALAR",
      kvkkFormTitle2: "Başvuru Formu İçinde bulunması gereken bilgiler",
      kvkkFormTitle3: "A. Başvuru Sahibi iletişim bilgileri:",
      kvkkFormTitle4: "B. Başvuru Sahibi’nin şirketimiz ile ilişkisi:",
      kvkkFormText1: `6698 Sayılı Kişisel Verilerin Korunması Kanunu’nda (“KVK Kanunu”)
      ilgili kişi olarak tanımlanan kişisel veri sahiplerine (“Bundan sonra
      “Başvuru Sahibi” olarak anılacaktır), KVK Kanunu’un 11’inci maddesinde
      kişisel verilerinin işlenmesine ilişkin birtakım taleplerde bulunma
      hakkı tanınmıştır.
      <br />
      <br />
      KVK Kanunu’nun 13’üncü maddesinin birinci fıkrası uyarınca; Başvuru
      Sahibi, veri sorumlusu olan Şirketimize bu haklara ilişkin olarak
      yapılacak başvuruların yazılı olarak veya Kişisel Verilerin Korunması
      Kurulu (“Kurul”) tarafından belirlenen diğer yöntemlerle tarafımıza
      iletilmesi gerekmektedir.
      <br />
      <br />
      Bu çerçevede “yazılı” olarak Şirketimize yapılacak başvurular, işbu
      formun çıktısı alınarak;
      <br />
      <br />
      <ul>
        <li>Başvuru Sahibi’nin şahsen başvurusu ile,</li>
        <li>Noter vasıtasıyla,</li>
        <li>
          Başvuru Sahibi’nce 5070 Sayılı Elektronik İmza Kanununda tanımlı
          olan “güvenli
        </li>
        <li>
          elektronik imza” ile imzalanarak Şirket kayıtlı elektronik posta
          adresine gönderilmek suretiyle, tarafımıza iletilebilecektir.
        </li>
      </ul>`,
      kvkkFormText2: ` Ayrıca, Kurul’un belirleyeceği diğer yöntemler duyurulduktan sonra bu
      yöntemler üzerinden de başvuruların ne şekilde alınacağı Şirketimizce
      duyurulacaktır.
      <br />
      <br />
      Tarafımıza iletilmiş olan başvurularınız KVK Kanunu’nun 13’üncü
      maddesinin 2’inci fıkrası gereğince, talebin niteliğine göre
      talebinizin bizlere ulaştığı tarihten itibaren otuz gün
      <br />
      içinde yanıtlandırılacaktır. Yanıtlarımız ilgili KVK Kanunu’nun
      13’üncü maddesi hükmü
      <br />
      gereğince yazılı veya elektronik ortamdan tarafınıza ulaştırılacaktır.
      <br />
      <br />
      (Başvurunuz ücretsiz olarak sonuçlandırılacak ancak, işlemin ayrıca
      bir maliyeti gerektirmesi hâlinde, Kurulca belirlenen tarifedeki ücret
      alınabilecektir.)`,
      kvkkFormText3: `Lütfen KVK Kanunu kapsamındaki talebinizi detaylı olarak belirtiniz:`,
      kvkkFormText4: `Lütfen başvurunuza vereceğimiz yanıtın tarafınıza bildirilme yöntemini
      seçiniz:`,
      kvkkFormText5: `Not: (E-posta yöntemini seçmeniz hâlinde size daha hızlı yanıt
        verebileceğiz.)
        <br />
        <br />
        (Vekâleten teslim alınması durumunda noter tasdikli vekâletname veya
        yetki belgesi olması gerekmektedir.)
        <br />
        İşbu başvuru formu, Şirketimiz ile olan ilişkinizi tespit ederek,
        varsa, Şirketimiz
        <br />
        tarafından işlenen kişisel verilerinizi eksiksiz olarak belirleyerek,
        ilgili başvurunuza doğru ve kanuni süresinde cevap verilebilmesi için
        tanzim edilmiştir.
        <br />
        Hukuka aykırı ve haksız bir şekilde veri paylaşımından
        kaynaklanabilecek hukuki risklerin bertaraf edilmesi ve
        <br />
        özellikle kişisel verilerinizin güvenliğinin sağlanması amacıyla,
        kimlik ve yetki tespiti için Şirketimiz ek evrak ve malumat (Nüfus
        cüzdanı veya sürücü belgesi sureti vb.) talep etme hakkını saklı
        tutar. Form kapsamında iletmekte olduğunuz taleplerinize ilişkin
        <br />
        bilgilerin doğru ve güncel olmaması ya da yetkisiz bir başvuru
        yapılması halinde
        <br />
        Şirketimiz, söz konusu yanlış bilgi ya da yetkisiz başvuru kaynaklı
        taleplerden dolayı mesuliyet kabul etmemektedir.
        <br />
        <br />
        Yukarıda belirttiğim talepler doğrultusunda, Şirketinize yapmış
        olduğum başvurumun Kanun’un 13üncü maddesi uyarınca değerlendirilerek
        tarafıma bilgi verilmesini rica ederim.
        <br />
        <br />
        İşbu başvuruda tarafınıza sağlamış olduğum belge ve bilgilerimin doğru
        ve güncel olduğu, şahsıma ait olduğunu beyan ve taahhüt ederim.
        <br />
        <br />
        İşbu başvuru formunda sağlamış olduğum bilgi ve belgelerin 6698 Sayılı
        Kişisel Verilerin Korunması Kanunun 13üncü maddesi uyarınca yapmış
        olduğum başvurunun
        <br />
        değerlendirilmesi, cevaplandırılması, başvurumun tarafıma
        ulaştırılması, kimliğimin ve adresimin tespiti amaçlarıyla sınırlı
        olarak Şirketiniz tarafından işlenmesine izin veriyorum.
        <br />
        <br />
        <br />
        Başvuru Sahibi (Kişisel Veri Sahibi) Adı Soyadı :
        <br />
        <br />
        <br />
        İmzası:
        <br />
        <br />
        Başvuru Tarihi :
        <br />
        <br />
        İmza :`,
      kvkkFormTableTh1: "Başvuru Yöntemi",
      kvkkFormTableTh2: "Başvurunun Yapılacağı Adres",
      kvkkFormTableTh3: "Başvuru Gönderiminde Belirtilecek Bilgi",
      kvkkFormTableTr1Td1:
        "Şahsen Başvuru (Başvuru sahibinin bizzat gelerek kimliğini teşvik edici belge ile başvurması)",
      kvkkFormTableTr1Td2: "Veri Sorumlusu Kurumun adresi buraya",
      kvkkFormTableTr1Td3: ` Zarfın üzerine “Kişisel Verilerin Korunması <br /> Kanunu Kapsamında Bilgi Talebi” yazılacaktır.`,
      kvkkFormTableTr2Td1: "İadeli Taahhütlü Posta",
      kvkkFormTableTr2Td2: "Veri Sorumlusu Kurumun adresi buraya",
      kvkkFormTableTr2Td3: ` Zarfın üzerine “Kişisel Verilerin Korunması<br />Kanunu Kapsamında Bilgi Talebi” yazılacaktır.`,
      kvkkFormTableTr3Td1: "Noter vasıtasıyla tebligat",
      kvkkFormTableTr3Td2: "Veri Sorumlusu Kurumun adresi buraya",
      kvkkFormTableTr3Td3: ` Zarfın üzerine “Kişisel Verilerin Korunması<br /> Kanunu Kapsamında Bilgi Talebi” yazılacaktır.`,
      kvkkFormTable2Th1: `İsim:`,
      kvkkFormTable2Th2: `Soy isim:`,
      kvkkFormTable2Th3: `TC Kimlik Numarası:`,
      kvkkFormTable2Th4: `Telefon Numarası:`,
      kvkkFormTable2Th5: `E-posta:`,
      kvkkFormTable2Th6: `Adres:`,

      kvkkFormTable3Th1: `Talep No`,
      kvkkFormTable3Th2: `Talep Konusu`,
      kvkkFormTable3Th3: `Kanuni Dayanak`,
      kvkkFormTable3Th4: `Seçiminiz`,

      kvkkFormTable3Tr1Td1: `Şirketinizin hakkımda kişisel veri işleyip işlemediğini öğrenmek istiyorum`,
      kvkkFormTable3Tr1Td2: `Kişisel Verilerin <br />
      Korunması Kanunu Madde 11/1 (a)`,
      kvkkFormTable3Tr2Td1: `Eğer Şirketiniz hakkımda Kişisel Veri işliyorsa bu veri işleme faaliyetleri hakkında bilgi talep ediyorum`,
      kvkkFormTable3Tr2Td2: `Kişisel Verilerin <br />
      Korunması Kanunu Madde 11/1 (b)`,
      kvkkFormTable3Tr3Td1: `Eğer Şirketiniz hakkımda Kişisel Veri işliyorsa bunların işlenme amacını ve bu amaca uygun kullanılıp kullanılmadığını öğrenmek istiyorum`,
      kvkkFormTable3Tr3Td2: `Kişisel Verilerin <br />
      Korunması Kanunu Madde 11/1 (c)`,
      kvkkFormTable3Tr4Td1: `Eğer Kişisel Verilerim Yurtiçinde veya Yurtdışında üçüncü kişilere aktarılıyorsa ,bu üçüncü kişileri bilmek istiyorum.`,
      kvkkFormTable3Tr4Td2: `Kişisel Verilerin <br />
      Korunması Kanunu Madde 11/1 (ç)`,
      kvkkFormTable3Tr5Td1: `Kişisel Verilerimin eksik yada yanlış işlendiğini düşünüyorum ve bunların düzeltilmesini istiyorum`,
      kvkkFormTable3Tr5Td2: `Kişisel Verilerin <br />
      Korunması Kanunu Madde 11/1 (d)`,
      kvkkFormTable3Tr6Td1: `Kişisel Verilerimin kanun ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş olmasına
      rağmen, işlenmesini gerektiren sebeplerin ortadan kalktığını düşünüyorum ve bu çerçevede kişisel verilerimin silinmesini  veya  yok  edilmesini talep ediyorum`,
      kvkkFormTable3Tr6Td2: `Kişisel Verilerin <br />
      Korunması Kanunu Madde 11/1 (e)`,
      kvkkFormTable3Tr7Td1: `Eksik veya yanlış işlendiğini düşündüğüm Kişisel Verilerimin aktarıldığı üçüncü kişiler nezdinde de düzeltilmesini istiyorum.`,
      kvkkFormTable3Tr7Td2: `Kişisel Verilerin <br />
      Korunması Kanunu Madde 11/1 (f)`,
      kvkkFormTable3Tr8Td1: `Kişisel Verilerimin kanun ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş olmasına
      rağmen, işlenmesini gerektiren sebeplerin ortadan kalktığını düşünüyorum ve bu çerçevede kişisel verilerimin üçüncü kişiler nezdinde de silinmesinin veya  yok  edilmesinin bildirilmesini  talep  ediyorum`,
      kvkkFormTable3Tr8Td2: `Kişisel Verilerin <br />
      Korunması Kanunu Madde 11/1 (g)`,
      kvkkFormTable3Tr9Td1: `Şirketiniz tarafından işlenen Kişisel Verilerimin münhasıran Otomatik Sistemler vasıtasıyla analiz edildiğini ve bu analiz neticesinde Şahsım aleyhine
      bir sonuç doğduğunu düşünüyorum. Bu sonuca itiraz ediyorum.`,
      kvkkFormTable3Tr9Td2: `Kişisel Verilerin <br />
      Korunması Kanunu Madde 11/1 (h)`,
      kvkkFormTable3Tr10Td1: `Kişisel Verilerimin Kanuna aykırı işlenmesi nedeniyle zarara uğradım. Bu zararın tazminini talep ediyorum.`,
      kvkkFormTable3Tr10Td2: `Kişisel Verilerin <br />
      Korunması Kanunu Madde 11/1 (i)`,
      kvkkFormTable3Tr11Td1: `Diğer Belirtiniz:`,

      kvkkFormTable4Th1: `Adresime gönderilmesini istiyorum.`,
      kvkkFormTable4Th2: `E-posta adresime gönderilmesini istiyorum.`,
      kvkkFormTable4Th3: `Elden teslim almak istiyorum.`,
      homePageTitle: "Delta Software",
      notFoundPageTitle: "Sayfa Bulunamadı | Delta Software",
      aboutPageTitle: "Hakkımızda | Delta Software",
      blogPageTitle: "Blog | Delta Software",
      careerPageTitle: "Kariyer | Delta Software",
      cookiePageTitle: "Çerez Politikası | Delta Software",
      kvkkPageTitle: "Kişisel Verilerin Korunması | Delta Software",
      kvkkFormPageTitle: "Kişisel Verilerin Korunması Form | Delta Software",
      apiServicesError: "Lütfen hizmet seçiniz",
      apiAgreementError: "Lütfen kabul ettiğinizi onaylayın",
      formSuccess: "Başarıyla gönderildi size dönüş yapacağız",
      formError:
        "Bilinmeyen bir hata ile karşılaşıldı lütfen bizimle iletişime geçiniz",
      fileSizeError: "Dosya boyutu 30 mb dan fazla olamaz",
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: language,
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
