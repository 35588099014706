import React, { useState } from "react";
import "./index.scss";
import {
  FooterMail,
  FooterSearch,
  FooterTel1,
  FooterTel2,
} from "../../assets/icons";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Toast } from "components/toastify/toastify";
import axios from "axios";

const Footer: React.FC = () => {
  const { t } = useTranslation();
  const [footerEmail, setFooterEmail] = useState<string>("");

  function smoothScroll(element?: string) {
    const elementTo = document.querySelector<HTMLElement>(
      element ? element : ".header"
    );
    if (elementTo) {
      elementTo.scrollIntoView({
        behavior: "auto",
      });
    }
  }

  const sunbmitForm = async () => {
    const hostname =
      window.location.hostname === "localhost"
        ? "http://localhost"
        : "https://www.deltasoftware.tech";

    let data = JSON.stringify({
      email: footerEmail,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${hostname}:6100/api/webForm/addForm`,

      headers: {
        apikey: "XH1vN8x3wihWEe0HIkZRxR4jw134WXScgWIu5G4C",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data);
        console.log(response.data.S);
        if (response.data.S === "T") {
          Toast("success", t("formSuccess"));
          setFooterEmail("");
        } else {
          Toast("error", t("formError"));
        }
      })
      .catch((error) => {
        console.log(error);
        Toast("error", t("formError"));
      });
  };

  return (
    <div className="footer">
      <div className="top"></div>
      <footer>
        <div className="item">
          <h2 className="title">{t("footerContact")}</h2>
          <ul className="menu">
            <li>
              <FooterTel1 />
              <Link to="tel:+902126400208">+90 212 640 02 08</Link>
            </li>
            <li>
              <FooterTel2 />
              <Link to="tel:+905519498699">+90 551 949 86 99</Link>
            </li>
            <li>
              <FooterMail />
              <Link to="mailto:deltasoftech@icloud.com">
                deltasoftech@icloud.com
              </Link>
            </li>
            <li>
              <Link to="https://www.google.com.tr/maps/search/Mega+Center+İş+Merkezi+C-26+Blok+Kat:5+Bayrampaşa+%2F+İstanbul/@41.0496971,28.9080097,21z?entry=ttu">
                Mega Center İş Merkezi C-26 Blok Kat:5 <br /> Bayrampaşa /
                İstanbul
              </Link>
            </li>
          </ul>
        </div>
        <div className="item">
          <h2 className="title">{t("footerInstitutional")}</h2>
          <ul className="menu">
            <li>
              <Link to="/about" onClick={() => smoothScroll()}>
                {t("footerMenuInstitutional1")}
              </Link>
            </li>
            <li>
              <Link to="/career">{t("footerMenuInstitutional2")}</Link>
            </li>
            <li>
              <Link to="/kvkk" onClick={() => smoothScroll()}>
                {t("footerMenuInstitutional3")}
              </Link>
            </li>
            <li>
              <Link to="/cookie" onClick={() => smoothScroll()}>
                {t("footerMenuInstitutional4")}
              </Link>
            </li>
            <li>
              <Link to="/kvkk-form" onClick={() => smoothScroll()}>
                {t("footerMenuInstitutional5")}
              </Link>
            </li>
          </ul>
        </div>
        <div className="item">
          <h2 className="title">{t("footerServices")}</h2>
          <ul className="menu">
            <li>
              <Link to="/" onClick={() => smoothScroll(".ourTechnologies")}>
                {t("footerMenuServices1")}
              </Link>
            </li>
            <li>
              <Link to="/" onClick={() => smoothScroll(".ourTechnologies")}>
                {t("footerMenuServices2")}
              </Link>
            </li>
            <li>
              <Link to="/" onClick={() => smoothScroll(".ourTechnologies")}>
                {t("footerMenuServices3")}
              </Link>
            </li>
            <li>
              <Link to="/" onClick={() => smoothScroll(".ourTechnologies")}>
                {t("footerMenuServices4")}
              </Link>
            </li>
            <li>
              <Link to="/" onClick={() => smoothScroll(".ourTechnologies")}>
                {t("footerMenuServices5")}
              </Link>
            </li>
            <li>
              <Link to="/" onClick={() => smoothScroll(".ourTechnologies")}>
                {t("footerMenuServices6")}
              </Link>
            </li>
          </ul>
        </div>
        <div className="item">
          <h2 className="title">{t("footerContactNow")}</h2>
          <h6 className="text">{t("footerMenuContactNowText")}</h6>
          <form
            onSubmit={(e) => {
              sunbmitForm();
              e.preventDefault();
            }}
          >
            <div className="input-container">
              <input
                type="email"
                value={footerEmail}
                required
                onChange={(e) => {
                  setFooterEmail(e.target.value);
                }}
                placeholder={t("footerMenuContactNowInput")}
              />
              <button type="submit">
                <FooterSearch />
              </button>
            </div>
          </form>
          <Link to="/">
            <img
              className="logo"
              src={"assets/imgs/logo-purple.png"}
              alt="delta-logo"
            />
          </Link>
        </div>
      </footer>
      <div className="bottom">
        <h6>
          All Right Reserved © {new Date().getFullYear()}
          <span>|</span>Delta Software
        </h6>
      </div>
    </div>
  );
};

export default Footer;
