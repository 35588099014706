import Layout from "layout";
import HomePage from "pages/homepage";
import CookiePolicyPage from "pages/cookiePolicy";
import AboutPage from "pages/about";
import KvkkPage from "pages/kvkk";
import KvkkFormPage from "pages/kvkkForm";
import BlogPage from "pages/blog";
import CareerPage from "pages/career";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NotFoundPage from "pages/404";

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="" element={<HomePage />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="cookie" element={<CookiePolicyPage />} />
          <Route path="kvkk" element={<KvkkPage />} />
          <Route path="kvkk-form" element={<KvkkFormPage />} />
          <Route path="blog" element={<BlogPage />} />
          <Route path="career" element={<CareerPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default AppRouter;
