import { useEffect, useState } from "react";
import "./index.scss";
import { HeaderIcon, HeaderMenuStart } from "../../assets/icons";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Header = () => {
  const { t } = useTranslation();
  const [active, setActive] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const blur = document.querySelector<HTMLElement>(".blur");
    const html = document.querySelector("html");
    const menu = document.querySelector<HTMLElement>(".menu");

    if (active) {
      if (blur && html && menu) {
        blur.style.display = "block";
        menu.style.transform = "translate(0, 0)";

        setTimeout(() => {
          blur.classList.add("active");
          html.classList.add("lock");
        }, 1);
      }
    } else {
      if (blur && html && menu) {
        blur.classList.remove("active");
        html.classList.remove("lock");
        setTimeout(() => {
          blur.style.display = "none";
          menu.style.transform = "translate(-10000px, -10000px)";
        }, 500);
      }
    }
  }, [active]);

  useEffect(() => {
    if (location.hash) {
      const elementTo = document.querySelector<HTMLElement>(
        `.${location.hash.replace("#", "")}`
      );
      if (elementTo) {
        window.scroll({
          top: elementTo.offsetTop,
          behavior: "smooth",
        });
      }
    }
  }, [location.hash]);

  function toggleActive() {
    if (active) {
      setActive(false);
    } else {
      setActive(true);
    }
  }

  function smoothScroll(element: string) {
    const elementTo = document.querySelector<HTMLElement>(element);

    if (location.pathname !== "/") {
      navigate(`/#${element.replace(".", "")}`);
    }

    setActive(false);
    setTimeout(() => {
      if (elementTo) {
        elementTo.scrollIntoView({
          behavior: "smooth",
        });
      }
    }, 100);
  }

  return (
    <div className="header">
      <div className="blur" onClick={() => setActive(false)}></div>

      <div className={active ? "menuToggle active" : "menuToggle"}>
        <div className="iconCont" onClick={toggleActive}>
          <span></span>
          <span></span>
        </div>
        <div className="menu">
          <div className="left">
            <Link to="/about" onClick={() => setActive(false)}>
              <h3>{t("headerMenuAbout")}</h3>
            </Link>
            <button onClick={() => smoothScroll(".services")}>
              <h3>{t("headerMenuServices")}</h3>
            </button>
            <Link to="/blog" onClick={() => setActive(false)}>
              <h3>{t("headerMenuBlog")}</h3>
            </Link>
            <Link to="/career" onClick={() => setActive(false)}>
              <h3>{t("headerMenuCareer")}</h3>
            </Link>
            <button onClick={() => smoothScroll(".footer")}>
              <h3>{t("headerMenuContact")}</h3>
            </button>
            <div
              className="bg-pruple"
              onClick={() => {
                smoothScroll(".projectForm");
              }}
            >
              <h3>{t("headerMenuProjectStart")}</h3>
              <HeaderMenuStart></HeaderMenuStart>
            </div>

            <div className="bottom">
              <div className="item">
                <h6>{t("headerMenuSocialMedia")}</h6>
                <div className="content">
                  <a
                    href="https://www.instagram.com/deltasoftwaretech/"
                    target="_blank"
                  >
                    INSTAGRAM
                  </a>
                  <a
                    href="https://www.youtube.com/@DeltaSoftwareTech"
                    target="_blank"
                  >
                    YOUTUBE
                  </a>
                </div>
              </div>
              <div className="item">
                <h6>{t("headerMenuSocialLanguage")}</h6>
                <div className="content">
                  <Link
                    to="/"
                    className={
                      localStorage.getItem("language") === "en" ? "active" : ""
                    }
                    onClick={() => {
                      localStorage.setItem("language", "en");
                      window.location.reload();
                    }}
                  >
                    {t("headerMenuSocialLanguageEnglish")}
                  </Link>
                  <Link
                    className={
                      localStorage.getItem("language") === "tr"
                        ? "active"
                        : !localStorage.getItem("language")
                        ? "active"
                        : ""
                    }
                    to="/"
                    onClick={() => {
                      localStorage.setItem("language", "tr");
                      window.location.reload();
                    }}
                  >
                    {t("headerMenuSocialLanguageTurkish")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="right">
            <img src={"assets/imgs/logo-sm.png"} alt="delta-logo" />
            <p>© 2023 DELTA SOFTWARE</p>
          </div>
        </div>
      </div>
      <Link to="/">
        <img className="logo" src={"assets/imgs/logo.png"} alt="delta-logo" />
      </Link>
      <div className="button">
        <button onClick={() => smoothScroll(".projectForm")}>
          {t("headerStartProjectButton")}
        </button>
        <HeaderIcon></HeaderIcon>
      </div>
    </div>
  );
};

export default Header;
